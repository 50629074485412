import { styled } from '@mui/material/styles';

interface StyledModalFooterProps {
	footerHasBackground?: boolean;
}

export const StyledModalFooter = styled('div', {
	shouldForwardProp: (prop) => prop !== 'footerHasBackground',
})<StyledModalFooterProps>(({ theme, footerHasBackground }) => ({
	display: 'flex',
	flexDirection: 'column',
	padding: '25px',
	borderTop: `1px solid ${theme.palette.primary.light}`,
	background: footerHasBackground ? theme.palette.secondary.main : theme.palette.primary.dark,
	borderRadius: '0px 0px 20px 20px',
	[theme.breakpoints.down('md')]: {
		padding: '3%',
	},
}));
