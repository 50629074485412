import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AmountText = styled(Typography)(({ theme }) => ({
	fontSize: theme.typography.fontSize,
	fontWeight: 700,
	paddingRight: theme.spacing(0.5),
	verticalAlign: 'middle',
	[theme.breakpoints.down('sm')]: {
		fontSize: 12,
	},
}));
