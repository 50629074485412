import { PaletteColor, styled } from '@mui/material/styles';

interface FooterLinkProps {
	hasBackground?: boolean;
}

export const FooterLink = styled('a', { shouldForwardProp: (prop) => prop !== 'hasBackground' })<FooterLinkProps>(
	({ theme, hasBackground }) => ({
		fontWeight: 600,
		lineHeight: '1.8',
		textDecoration: 'none',
		color: hasBackground
			? theme.palette.getContrastText(theme.palette.secondary.main)
			: theme.palette.custom
				? theme.palette.getContrastText(theme.palette.custom.main)
				: theme.palette.getContrastText(theme.palette.primary[700 as unknown as keyof PaletteColor]),
		'&::after': {
			content: "'|'",
			margin: theme.spacing(0, 3),
			[theme.breakpoints.down('sm')]: {
				content: 'none',
			},
		},
		'&:last-child::after': {
			content: 'none',
		},
		'&:hover': {
			textDecoration: 'underline',
		},
	}),
);
