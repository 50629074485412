import { fetchWrapper, RequestMethod } from 'helpers';

export const audit = async (
	paymentIdentifier: string,
	clientID: string,
	action: string,
	metadata?: unknown,
): Promise<void> =>
	fetchWrapper<void>('/audit', {
		method: RequestMethod.POST,
		body: {
			paymentIdentifier,
			action,
			clientID,
			metadata,
		},
	});
