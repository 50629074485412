import { PaletteColor, keyframes, styled } from '@mui/material/styles';

const rotating = keyframes`
	25% {
		transform: translateX(42px) rotate(-90deg) scale(0.5);
	}
	50% {
		transform: translateX(42px) translateY(42px) rotate(-179deg);
	}
	50.1% {
		transform: translateX(42px) translateY(42px) rotate(-180deg);
	}
	75% {
		transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	}
	100% {
		transform: rotate(-360deg);
	}
`;

export const Cube = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.secondary.main,
	width: 15,
	height: 15,
	position: 'absolute',
	top: 0,
	left: 0,
	animation: `${rotating} 1.8s infinite ease-in-out`,
}));

export const SecondCube = styled('div')(({ theme }) => ({
	width: 15,
	height: 15,
	position: 'absolute',
	top: 0,
	left: 0,
	animation: `${rotating} 1.8s infinite ease-in-out`,
	backgroundColor: theme.palette.info['A700' as unknown as keyof PaletteColor],
	animationDelay: '-0.9s',
}));
