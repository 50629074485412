import { green, purple, red } from '@mui/material/colors';
import { PaletteOptions } from '@mui/material/styles/createPalette';

import { Palette } from 'theme/ThemeWrapper';

import { dark, skyBlue } from './palette';

export type themesList = 'light' | 'dark';
declare module '@mui/material/styles/createPalette' {
	interface Palette {
		custom?: Palette['primary'];
	}
	interface PaletteOptions {
		custom?: PaletteOptions['primary'];
	}
}

export type themes = {
	[K in themesList]: {
		palette: PaletteOptions;
	};
};

export const themes: themes = {
	light: {
		palette: {
			mode: 'light',
			primary: dark,
			secondary: {
				light: skyBlue[100],
				main: skyBlue[500],
				dark: skyBlue[900],
			},
			background: {
				paper: '#FFFFFF',
				default: '#f6f7f9',
			},
			error: red,
			success: green,
			info: purple,
		},
	},
	dark: {
		palette: {
			mode: 'dark',
			primary: dark,
			secondary: {
				light: skyBlue[100],
				main: skyBlue[500],
				dark: skyBlue[900],
			},
			background: {
				paper: '#1E2125',
				default: '#121212',
			},
			error: red,
			success: green,
			info: purple,
		},
	},
};

export const customiseThemePalette = (paletteOptions: Palette | null) => {
	if (!paletteOptions) {
		return themes.light;
	}
	const { palette } = themes.light;
	const customProps = paletteOptions.secondary
		? {
				custom: {
					main: paletteOptions.secondary,
				},
			}
		: {};

	return {
		...themes.light,
		palette: {
			...palette,
			secondary: {
				...palette.secondary,
				main: paletteOptions.main,
			},
			error: {
				main: '#ae0000',
			},
			...customProps,
		},
	};
};
