import { styled } from '@mui/material/styles';

export const StyledSubtitle = styled('h1')(({ theme }) => ({
	marginBottom: theme.spacing(2),
	fontSize: '1.8em',
	padding: theme.spacing(0, 6),
	fontWeight: 700,
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(0),
		marginBottom: theme.spacing(2),
		fontSize: 20,
	},
}));
