import { useEffect, useRef, useState } from 'react';

import { SECOND } from 'helpers/formatTime';

export const useTimer = (timeoutLimit: number) => {
	const [isSessionExpired, setSessionExpired] = useState(false);
	const currentTime = useRef(new Date().getTime());
	const sessionEndTime = useRef(new Date().getTime() + timeoutLimit);
	const [timeLeft, setTimeLeft] = useState(sessionEndTime.current - currentTime.current);

	useEffect(() => {
		const interval = setInterval(() => {
			currentTime.current = new Date().getTime();
			setTimeLeft(sessionEndTime.current - currentTime.current);

			if (sessionEndTime.current - currentTime.current <= 0) {
				clearInterval(interval);
				setSessionExpired(true);
			}

			return () => {
				clearInterval(interval);
			};
		}, SECOND);
	}, []);

	return { timeLeft, isSessionExpired };
};
