import { styled } from '@mui/material/styles';

interface StyledPaymentHeaderTitleProps {
	headerHasBackground?: boolean;
}

export const StyledPaymentHeaderTitle = styled('div', {
	shouldForwardProp: (prop) => prop !== 'headerHasBackground',
})<StyledPaymentHeaderTitleProps>(({ theme, headerHasBackground }) => ({
	fontWeight: 700,
	paddingBottom: '8px',
	marginLeft: theme.spacing(4),
	textAlign: 'left',
	maxWidth: '60%',
	marginTop: theme.spacing(2),
	fontSize: '1.5rem',
	lineBreak: 'anywhere',
	color: headerHasBackground ? theme.palette.getContrastText(theme.palette.secondary.main) : theme.palette.text.primary,
	[theme.breakpoints.down('sm')]: {
		fontSize: 15,
		marginLeft: theme.spacing(3),
	},
}));
