import { Box, Theme } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

import { SUMMARY_MAPPINGS } from 'components/features/Configuration/constants';

import { StyledCurrencyLabel } from './StyledCurrencyLabel';
import { amountMatchesPriceAndQuantity, getHasNumericFieldsError } from '../../helpers/getHaveFieldsErrors';
import { getInputHelperText, getInputLabelText } from '../../helpers/getInputHelperText';
import { useSetInputValue, useConfiguration, useConfig, useAmountSeparators } from '../../hooks';
import { StyledErrorMessage } from '../../StyledErrorMessage';
import { StyledInputHelper } from '../../StyledInputHelper';
import { StyledLabel } from '../../StyledLabel';
import { Input } from '../../types/types';

export const CurrencyInput: React.FunctionComponent<{
	input: Input;
	areErrorsVisible: boolean;
	hideLabel?: boolean;
	isDisabled?: boolean;
}> = ({ input, areErrorsVisible, hideLabel = false, isDisabled = false }) => {
	const setInputValue = useSetInputValue();
	const configuration = useConfiguration();
	const config = useConfig();
	const { thousandsSeparator, decimalSeparator } = useAmountSeparators();
	const { currencySymbol, currency } = config.paymentSystem;

	const numberFormatParts = new Intl.NumberFormat(undefined, { currency, style: 'currency' }).formatToParts();
	const symbol = currencySymbol || numberFormatParts.find((part) => part.type === 'currency')?.value || '';

	useEffect(() => {
		if (areErrorsVisible) {
			setFieldErrorVisibility();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [areErrorsVisible]);

	const [fieldHasError, toggleFieldError] = useState<boolean>(false);
	const [priceQuantityError, togglePriceQuantityError] = useState<boolean>(false);

	const setFieldErrorVisibility = (): void => {
		const inputFieldHasError = getHasNumericFieldsError(input);
		toggleFieldError(inputFieldHasError);
	};

	useEffect(() => {
		if (input.summaryMapping === SUMMARY_MAPPINGS.AMOUNT && !amountMatchesPriceAndQuantity(config)) {
			toggleFieldError(true);
			togglePriceQuantityError(true);
		} else {
			toggleFieldError(false);
			togglePriceQuantityError(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [config]);

	const matchesMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const intl = useIntl();
	let inputErrorMessage = '';
	if (priceQuantityError) {
		inputErrorMessage = intl.formatMessage({ id: 'amount-quantity-validation_error' });
	} else if (input.validation?.message) {
		inputErrorMessage = input.validation.message;
	} else {
		inputErrorMessage = intl.formatMessage({ id: 'quickpay-input__error' });
	}

	const handleChange = (inputValue: number) => {
		if (setInputValue) {
			setInputValue(configuration, input.id, inputValue);
		}
		if (fieldHasError) {
			setFieldErrorVisibility();
		}
	};

	const checkIfValid = ({ value }: NumberFormatValues) => (value.startsWith('0') ? value.indexOf('.') === 1 : true);

	const handleOnBlur = () => {
		setFieldErrorVisibility();
	};

	const currencyInputProps = matchesMediaQuery
		? {
				startAdornment: (
					<InputAdornment position="start">
						<StyledCurrencyLabel htmlFor="balance-input">{symbol}</StyledCurrencyLabel>
					</InputAdornment>
				),
				readOnly: false,
				'aria-describedby': 'helper-text',
			}
		: { readOnly: false, 'aria-describedby': 'helper-text' };

	const inputHelperText = getInputHelperText(config, input);
	const inputLabelText = getInputLabelText(config, input);

	const labelStyle = {
		...(input.inputTextFormat?.label?.fontSize &&
			input.inputTextFormat?.label?.fontSize > 0 && { fontSize: input.inputTextFormat?.label?.fontSize }),
		...(input.inputTextFormat?.label?.isBold ? { fontWeight: 700 } : { fontWeight: 400 }),
	};

	const helperTextStyle = {
		...(input.inputTextFormat?.helperText?.fontSize &&
			input.inputTextFormat?.helperText?.fontSize > 0 && { fontSize: input.inputTextFormat?.helperText?.fontSize }),
		...(input.inputTextFormat?.helperText?.isBold ? { fontWeight: 700 } : { fontWeight: 400 }),
	};

	const errorTextStyle = {
		...(input.inputTextFormat?.error?.fontSize &&
			input.inputTextFormat?.error?.fontSize > 0 && { fontSize: input.inputTextFormat?.error?.fontSize }),
		...(input.inputTextFormat?.error?.isBold ? { fontWeight: 700 } : { fontWeight: 400 }),
	};

	return (
		<>
			{!hideLabel && <StyledLabel style={input.inputTextFormat && labelStyle}>{inputLabelText}</StyledLabel>}
			<StyledInputHelper id="currency-input-helper-text" style={input.inputTextFormat && helperTextStyle}>
				{inputHelperText}
			</StyledInputHelper>
			<Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
				{!matchesMediaQuery && (
					<StyledCurrencyLabel htmlFor="balance-input" aria-label={`amount value ${input.value} ${symbol}`}>
						{symbol}
					</StyledCurrencyLabel>
				)}
				<NumericFormat
					value={input.value?.toString() || ''}
					displayType="input"
					{...thousandsSeparator}
					{...decimalSeparator}
					fixedDecimalScale
					decimalScale={2}
					customInput={TextField}
					name={input.label || 'amount'}
					required={input.validation?.required}
					placeholder={input.placeholder && input.placeholder}
					allowNegative={false}
					variant="outlined"
					id="balance-input"
					isAllowed={checkIfValid}
					onValueChange={(values) => {
						const { floatValue } = values;
						if (typeof floatValue !== 'undefined') {
							handleChange(floatValue);
						}
					}}
					onBlur={handleOnBlur}
					InputProps={currencyInputProps}
					disabled={configuration.hasLineQuantity || input.isDisabled || isDisabled}
					aria-disabled={configuration.hasLineQuantity ? true : false}
					tabIndex={configuration.hasLineQuantity ? 0 : -1}
					autoComplete="off"
					sx={(theme: Theme) => ({
						maxWidth: '100%',
						width: '90%',
						boxShadow: theme.shadows[3],
						borderRadius: '10px',
						margin: theme.spacing(0, 2),

						'& input': {
							fontSize: 20,
							fontWeight: 700,
							padding: `calc(${theme.spacing(2)} - 6px) ${theme.spacing(2)} !IMPORTANT`,
							[theme.breakpoints.down('sm')]: {
								fontSize: 22,
								padding: `calc(${theme.spacing(2)} - 6px) ${theme.spacing(6)} calc(${theme.spacing(2)} - 6px) ${theme.spacing(1)}`,
								textAlign: 'center',
							},
						},
						[theme.breakpoints.down('sm')]: {
							margin: theme.spacing(0, 0, 2),
						},
					})}
				/>
			</Box>
			<StyledErrorMessage
				style={input.inputTextFormat && errorTextStyle}
				className={classNames({ visible: fieldHasError })}
				aria-live="polite"
				role="alert"
			>
				{inputErrorMessage}
			</StyledErrorMessage>
		</>
	);
};
