import { styled } from '@mui/material/styles';

export const StyledImage = styled('img')(({ theme }) => ({
	height: 'auto',
	width: '65%',
	maxHeight: 230,
	margin: theme.spacing(3, 0, 4),
	[theme.breakpoints.up('xs')]: {
		width: '50%',
	},
	[theme.breakpoints.up('sm')]: {
		margin: theme.spacing(5, 0, 6),
	},
}));
