export enum StripeErrorCodes {
	CARD_DECLINED = 'card_declined',
	EXPIRED_CARD = 'expired_card',
	CARD_AUTHENTICATION_FAILED = 'payment_intent_authentication_failure',
	CARD_DECLINE_RATE_LIMIT_EXCEEDED = 'card_decline_rate_limit_exceeded',
}

export enum StripeDeclineCodes {
	DO_NOT_HONOR = 'do_not_honor',
	GENERIC_DECLINE = 'generic_decline',
	TRANSACTION_NOT_ALLOWED = 'transaction_not_allowed',
	INVALID_ACCOUNT = 'invalid_account',
	INSUFFICIENT_FUNDS = 'insufficient_funds',
}
