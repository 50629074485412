import { Box } from '@mui/material';
import { PaletteColor, styled } from '@mui/material/styles';

export const ErrorContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	height: '100%',
	flex: 1,
	backgroundColor: theme.palette.primary[700 as unknown as keyof PaletteColor],
	padding: theme.spacing(4, 2),
	[theme.breakpoints.up('xs')]: {
		padding: theme.spacing(6),
	},
}));
