import { Container } from '@mui/material';
import React from 'react';

import { RouterWrapper } from 'components/common';
import { routes } from 'routes/routes';

export const AppWrapper = () => {
	return (
		<Container
			disableGutters
			maxWidth={false}
			sx={(theme) => ({
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				overflow: 'auto',
				backgroundColor: theme.palette.background.paper,
				maxWidth: '100%',
			})}
		>
			<RouterWrapper routes={routes} />
		</Container>
	);
};
