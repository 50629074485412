import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAdditionalMessage = styled(Typography)(({ theme }) => ({
	margin: theme.spacing(2, 0, 4),
	textAlign: 'center',
	[theme.breakpoints.down('sm')]: {
		fontSize: 12,
	},
}));
