import { Typography, TypographyProps } from '@mui/material';
import { PaletteColor, styled } from '@mui/material/styles';

interface FooterParagraphProps extends TypographyProps {
	hasBackground?: boolean;
}

export const FooterParagraph = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'hasBackground',
})<FooterParagraphProps>(({ theme, hasBackground }) => ({
	fontWeight: 600,
	lineHeight: '1.8',
	color: hasBackground
		? theme.palette.getContrastText(theme.palette.secondary.main)
		: theme.palette.custom
			? theme.palette.getContrastText(theme.palette.custom.main)
			: theme.palette.getContrastText(theme.palette.primary[700 as unknown as keyof PaletteColor]),
}));
