import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { useQueryParams } from 'hooks';

import { StripeBankPaymentConfirmationWrapper } from './StripeBankPaymentConfirmationWrapper';
import { StripePaymentModal } from './StripePaymentModal';
import { StripePaymentSession, PaymentSession } from './types/paymentSystem';
import { mapPaymentParameters } from '../Configuration/helpers';

export const StripePaymentModule: React.FunctionComponent<{
	sessionExpired?: boolean;
	timeLeft?: number;
	sessionData: PaymentSession | null;
	sessionCreationLoading: boolean;
	sessionCreationError: Error | null;
	publicKey: string;
}> = ({ sessionExpired = false, timeLeft, sessionData, sessionCreationLoading, sessionCreationError, publicKey }) => {
	const intl = useIntl();
	const queryParams = useQueryParams();
	const mappedQueryParameters = mapPaymentParameters(queryParams);
	const { paymentIntentClientSecret } = mappedQueryParameters;

	const stripePublicKeyError = intl.formatMessage({ id: 'stripe-error__noPublicKey' });
	const [stripePromise] = useState(() =>
		loadStripe(publicKey, {
			betas: ['blocked_card_brands_beta_2'],
			apiVersion: '2024-06-20',
		}),
	);

	if (!publicKey) {
		throw Error(stripePublicKeyError);
	}

	if (paymentIntentClientSecret) {
		return <StripeBankPaymentConfirmationWrapper paymentIntentClientSecret={paymentIntentClientSecret} />;
	}

	if (!sessionCreationLoading && sessionData?.session) {
		const clientSecret = (sessionData.session as StripePaymentSession).clientSecret;
		const cardBrandsMapping: Record<string, string> = {
			amex: 'american_express',
			discover: 'discover_global_network',
		};
		const allowedCardBrands = (sessionData.session as StripePaymentSession).allowedCardBrands?.map((brand) =>
			cardBrandsMapping[brand] ? cardBrandsMapping[brand] : brand,
		) || ['visa', 'mastercard'];

		const options = clientSecret
			? { clientSecret, paymentMethodCreation: 'manual', allowedCardBrands }
			: { allowedCardBrands };
		return (
			<Elements stripe={stripePromise} options={options}>
				<StripePaymentModal
					sessionExpired={sessionExpired}
					timeLeft={timeLeft}
					sessionData={sessionData}
					sessionCreationLoading={sessionCreationLoading}
					sessionCreationError={sessionCreationError}
				/>
			</Elements>
		);
	}
	return <></>;
};
