import { styled } from '@mui/material/styles';

export const ErrorImage = styled('img')(({ theme }) => ({
	maxWidth: 350,
	height: 'auto',
	width: '70%',
	margin: theme.spacing(5, 0, 6),
	[theme.breakpoints.up('xs')]: {
		width: '50%',
	},
}));
