import React, { createContext, FunctionComponent, useState, useContext, useCallback, PropsWithChildren } from 'react';

import { Loading } from 'components/common';
import { formatExternalUrl } from 'helpers';

import { audit } from './actions/audit';
import { calculatePaymentsTotalAmount, mapReturnUrlParameters } from './helpers';
import { PaymentResultContainer } from './PaymentResultContainer';
import { PaymentContextType, PaymentData } from './types/payment';
import { removeInitialParametersFromSession } from '../Configuration/helpers';
import { usePaymentParameters, useGetIsQuickPayPage, useConfiguration } from '../Configuration/hooks';
import { PaymentConfirmation } from '../PaymentFeedback/PaymentConfirmation';
import { PaymentError } from '../PaymentFeedback/PaymentError';

export enum PaymentResult {
	COMPLETED = 'COMPLETED',
	FAILED = 'FAILED',
	CANCELLED = 'CANCELLED',
}

const INITIAL_CONTEXT_VALUE = {
	initiatePayment: () => null,
	updatePayment: () => null,
	onClose: () => null,
	onCompletePayment: () => null,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onError: (error: Error | null, returnUrlParams?: Record<string, string | number>) => null,
	onLoading: () => null,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onCancel: (returnUrlParams?: Record<string, string | number>) => null,
	paymentData: { amount: 0 },
	isLoading: false,
	paymentError: null,
	paymentLoading: false,
	isPaymentModalOpen: false,
	paymentDeclined: false,
	paymentCompleted: false,
	paymentIdentifier: undefined,
};
const DECLINED = 'D';

export const PaymentContext = createContext<PaymentContextType>(INITIAL_CONTEXT_VALUE);

export const PaymentProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
	const paymentParameters = usePaymentParameters();
	const isQuickPayPage = useGetIsQuickPayPage();
	const {
		config: { clientID },
		data: { identifier },
	} = useConfiguration();

	const [context, setContextValue] = useState<PaymentContextType>({
		...INITIAL_CONTEXT_VALUE,
		paymentData: { amount: calculatePaymentsTotalAmount(paymentParameters.payments) },
	});

	const updateContext = (data: Partial<PaymentContextType>) =>
		setContextValue((oldState) => ({ ...oldState, ...data }));

	const initiatePayment = (data: PaymentData) => {
		return updateContext({ paymentData: { ...data }, isPaymentModalOpen: true, paymentDeclined: false });
	};

	const onClose = useCallback(() => {
		updateContext({ isPaymentModalOpen: false });
	}, []);

	const formatDeclinedReturnUrl = useCallback(
		(returnUrlParams?: Record<string, string | number>): string | undefined => {
			const backUrlParameters = returnUrlParams || {
				responseCode: DECLINED,
				sessionId: paymentParameters.sessionId || '',
			};
			const mappedBackUrlParameters = mapReturnUrlParameters(backUrlParameters);
			if (paymentParameters.returnUrl) {
				return formatExternalUrl(paymentParameters.returnUrl, mappedBackUrlParameters);
			}
		},
		[paymentParameters.sessionId, paymentParameters.returnUrl],
	);

	const onCompletePayment = useCallback(
		async (backUrlParameters: Record<string, string | number>) => {
			if (!isQuickPayPage) {
				removeInitialParametersFromSession();
			}
			if (paymentParameters.returnUrl && !isQuickPayPage) {
				const mappedBackUrlParameters = mapReturnUrlParameters(backUrlParameters);
				const url = formatExternalUrl(paymentParameters.returnUrl, mappedBackUrlParameters);
				if (url) {
					await audit(identifier, clientID, 'complete-payment-return-url', {
						url,
						parameteres: mappedBackUrlParameters,
					});
					window.location.href = url;
					return;
				}
			}
			updateContext({
				isPaymentModalOpen: false,
				isLoading: false,
				paymentCompleted: true,
				paymentIdentifier: backUrlParameters.identifier ? String(backUrlParameters.identifier) : undefined,
			});
		},
		[clientID, identifier, isQuickPayPage, paymentParameters.returnUrl],
	);

	const onError = useCallback(
		async (error: Error | null, returnUrlParams?: Record<string, string | number>) => {
			if (error) {
				const url = formatDeclinedReturnUrl(returnUrlParams);
				if (url) {
					await audit(identifier, clientID, 'error-payment-return-url', { url });
					window.location.href = url;
					return;
				}
			}
			updateContext({ paymentError: error });
		},
		[clientID, formatDeclinedReturnUrl, identifier],
	);

	const onCancel = useCallback(
		async (returnUrlParams?: Record<string, string | number>, sessionExpired?: boolean) => {
			const url = formatDeclinedReturnUrl(returnUrlParams);
			if (url && !isQuickPayPage) {
				await audit(identifier, clientID, 'cancelled-payment-return-url', { url });
				window.location.href = url;
				return;
			}

			updateContext({
				...(!isQuickPayPage && { paymentError: new Error('Payment Declined') }),
				paymentDeclined: true,
				isPaymentModalOpen: false,
				...(sessionExpired && { paymentSessionExpired: true }),
			});
		},
		[clientID, formatDeclinedReturnUrl, identifier, isQuickPayPage],
	);

	const onLoading = useCallback((isLoading: boolean) => {
		updateContext({ paymentLoading: isLoading });
	}, []);

	const updatePayment = (data: PaymentData) => {
		return updateContext({ paymentData: { ...data } });
	};

	const value = {
		...context,
		initiatePayment,
		updatePayment,
		onClose,
		onCompletePayment,
		onError,
		onLoading,
		onCancel,
	};

	const { paymentData, paymentCompleted, paymentError, paymentIdentifier } = context;

	if (isQuickPayPage && !paymentCompleted && !paymentError) {
		return <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>;
	}

	if (!paymentData.amount && !isQuickPayPage) {
		return <Loading />;
	}

	if (paymentError || context.paymentDeclined || context.paymentSessionExpired) {
		return (
			<PaymentResultContainer>
				<PaymentError declined={context.paymentDeclined} sessionExpired={context.paymentSessionExpired} />
			</PaymentResultContainer>
		);
	}
	return paymentCompleted ? (
		<PaymentResultContainer>
			<PaymentConfirmation identifier={paymentIdentifier} />
		</PaymentResultContainer>
	) : (
		<PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
	);
};

export const usePaymentContext = () => {
	const context = useContext(PaymentContext);
	if (context === undefined) {
		throw new Error('usePaymentContext must be used within a PaymentProvider');
	}
	return context;
};
