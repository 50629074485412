import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledReturnButton = styled(Button)(({ theme }) => ({
	width: '50%',
	textTransform: 'none',
	marginBottom: theme.spacing(3),
	fontSize: 20,
	[theme.breakpoints.down('sm')]: {
		width: '100%',
		padding: `calc(${theme.spacing(1)} - 2px)`,
		fontSize: 18,
	},
}));
