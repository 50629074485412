import { styled } from '@mui/material/styles';

interface StyledModalHeaderProps {
	headerHasBackground?: boolean;
}

const midScreenHeight = 706;

export const StyledModalHeader = styled('div', {
	shouldForwardProp: (prop) => prop !== 'headerHasBackground',
})<StyledModalHeaderProps>(({ theme, headerHasBackground }) => ({
	display: 'flex',
	overflow: 'hidden',
	flexDirection: 'column',
	padding: '4% 4% 2% 4%',
	backgroundColor: headerHasBackground ? theme.palette.secondary.main : 'transparent',
	borderRadius: '20px 20px 0 0',
	borderBottom: !headerHasBackground ? `1px solid ${theme.palette.divider}` : undefined,
	[theme.breakpoints.down('sm')]: {
		display: 'none',
	},
	[`@media (max-height: ${midScreenHeight}px)`]: {
		padding: '2% 0',
	},
}));
