import { styled } from '@mui/material/styles';

export const CurrencyLabel = styled('label')(({ theme }) => ({
	fontWeight: 700,
	color: 'rgba(0, 0, 0, 0.87)',
	fontSize: 48,
	[theme.breakpoints.down('sm')]: {
		fontSize: 35,
	},
}));
