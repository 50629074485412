import { PaymentSystemBaseConfiguration } from 'components/features/Configuration/types';
import { UserAddress } from 'components/features/QuickPay/types/types';

export enum PaymentSystemType {
	MASTERCARD = 'MASTERCARD',
	STRIPE = 'STRIPE',
	GLOBALPAYMENTS = 'GLOBALPAYMENTS',
}

export enum StripePaymentType {
	CARD = 'CARD',
	WALLETS = 'WALLETS',
	OPENBANKING = 'OPENBANKING',
}

export enum StripePaymentStatus {
	SUCCEEDED = 'succeeded',
	REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
	PROCESSING = 'processing',
}

export interface MastercardPaymentSystem extends PaymentSystemBaseConfiguration {
	type: PaymentSystemType.MASTERCARD;
	merchantId: string;
	apiGatewayUrl: string;
	apiVersion: number;
}

export interface StripePaymentSystem extends PaymentSystemBaseConfiguration {
	publicKey: string;
}

export interface InitiatePaymentSession {
	paymentSession: PaymentSession;
	newIdentifier?: string;
}

export interface PaymentSession {
	session: MastercardPaymentSession | StripePaymentSession | GlobalPaymentsPaymentSession; // will contain types for all PSP defined
	email?: string;
	address?: UserAddress;
	paymentMethodId?: string;
}

export interface PaymentInitiation {
	paymentId: string;
	status: string;
}

export interface MotoPaymentSession extends PaymentSession {
	paymentMethod: string;
}

export interface MastercardPaymentSession {
	type: PaymentSystemType.MASTERCARD;
	sessionId?: string;
	amount?: string;
	description?: string;
	referenceNumber?: string;
	paymentConfirmationResult?: string;
}

export interface StripePaymentSession {
	type: PaymentSystemType.STRIPE;
	amount?: number;
	clientSecret?: string;
	chargeId?: string;
	paymentType?: string;
	publicKey?: string;
	paymentMethod?: string;
	isMoto?: boolean;
	allowedCardBrands?: string[];
}

export interface GlobalPaymentsDetails {
	ACCOUNT_ID: string;
	AMOUNT: string;
	CURRENCY: string;
	HPP_VERSION: string;
	MERCHANT_ID: string;
	ORDER_ID: string;
	SHA1HASH: string;
	SHARED_SECRET: string;
	TIMESTAMP: string;
	HPP_CAPTURE_ADDRESS: boolean;
	HPP_3DS2_BILLING_DETAILS_REQUIRED: boolean;
	HPP_CUSTOMER_EMAIL: string;
}

export interface GlobalPaymentsPaymentSession {
	type: PaymentSystemType.GLOBALPAYMENTS;
	bodyData?: GlobalPaymentsDetails;
	validatedData?: GlobalPaymentsResult;
	amount?: number;
}

export interface GlobalPaymentsResult {
	AMOUNT: string;
	AUTHCODE: string;
	MERCHANT_ID: string;
	MESSAGE: string;
	ORDER_ID: string;
	PASREF: string;
	RESULT: string;
	SHA1HASH: string;
	TIMESTAMP: string;
	amount_to_validate: string;
	clientID?: string;
	entity?: string;
	isQuickPay?: boolean;
}
