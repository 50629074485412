import React, { useState, createContext, FunctionComponent, PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';

import en from '../lang/en.json';

const DEFAULT_LANGUAGE = 'en';

const translations = {
	en,
};

type LanguagesMapper<T> = {
	[K in keyof T]: T[K];
};

type Languages = keyof LanguagesMapper<typeof translations>;

type Context = {
	locale: Languages;
	messages: Record<string, string>;
	changeLang: (language: Languages) => void;
};

const initialContext: Context = {
	locale: DEFAULT_LANGUAGE,
	messages: translations[DEFAULT_LANGUAGE],
	changeLang: (): void => undefined,
};

const LangContext = createContext<Context>(initialContext);

const LangContextProvider: FunctionComponent<PropsWithChildren> = ({ children }): JSX.Element => {
	const [lang, setLang] = useState<Context>(initialContext);

	const changeLang = (language: Languages) => {
		setLang((prevState) => ({
			...prevState,
			locale: language,
			messages: translations[language],
		}));
	};

	return (
		<LangContext.Provider value={{ ...lang, changeLang }}>
			<IntlProvider locale={lang.locale} defaultLocale={DEFAULT_LANGUAGE} messages={lang.messages}>
				{children}
			</IntlProvider>
		</LangContext.Provider>
	);
};

export { LangContext, LangContextProvider };
