import { useEffect, useState } from 'react';

type useFetcherReturnType<T> = [T, boolean, Error | null, () => void];
type actionType<T> = (() => Promise<T>) | undefined;

export const useFetcher = <T>(
	action: actionType<T>,
	initialData: T,
	defaultLoadingValue = false,
): useFetcherReturnType<T> => {
	const [state, setState] = useState<{ isLoading: boolean; error: Error | null; data: T }>({
		isLoading: defaultLoadingValue,
		error: null,
		data: initialData,
	});

	const handleErrorDismiss = (): void => {
		setState((oldState) => ({ ...oldState, error: null }));
	};

	useEffect(() => {
		let isMounted = true;
		const resolveAction = async (): Promise<void> => {
			try {
				if (!action) {
					return;
				}
				setState((oldState) => ({ ...oldState, isLoading: true, error: null }));
				const actionData = await action();

				if (isMounted) {
					setState({ isLoading: false, error: null, data: actionData });
				}
			} catch (e) {
				if (isMounted) {
					setState((oldState) => ({ ...oldState, isLoading: false, error: e as Error }));
				}
			}
		};
		resolveAction();
		return function cleanup(): void {
			isMounted = false;
		};
	}, [action]);
	return [state.data, state.isLoading, state.error, handleErrorDismiss];
};
