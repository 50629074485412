import { INPUTS_TYPES, SUMMARY_MAPPINGS } from 'components/features/Configuration/constants';
import { Configuration } from 'components/features/Configuration/types';
import { roundToPrecision } from 'components/features/Payment/helpers';

import { Input, QuickPayInputValidation } from '../types/types';

export const getHasNumericFieldsError = (input: Input): boolean => {
	const validation: QuickPayInputValidation | undefined = input.validation;
	const stringedValue = String(input?.value).replace(',', '');
	const value = Number(stringedValue);

	if (validation) {
		const { minValue, maxValue, required } = validation;
		const min = minValue || 0;
		const max = maxValue || Number.MAX_SAFE_INTEGER;

		if (value > max || value < min || Boolean(required && isNaN(value))) {
			return true;
		}
		return false;
	}
	return false;
};

export const getHasStringFieldsError = (input: Input): boolean => {
	const validation: QuickPayInputValidation | undefined = input.validation;
	const value = input?.value;
	if (validation) {
		const { minLength, maxLength, required, customFormat } = validation;
		let isRegxFormatValid = true;
		if (value && customFormat) {
			const regFormat = new RegExp(customFormat);
			isRegxFormatValid = regFormat.test(String(value));
		}
		if (
			(value && maxLength && String(value).length > maxLength) ||
			(value && minLength && String(value).length < minLength) ||
			(required && !value) ||
			!isRegxFormatValid
		) {
			return true;
		}
		return false;
	}
	return false;
};

export const requiredFieldHasValue = (input: Input): boolean => {
	const validation: QuickPayInputValidation | undefined = input.validation;
	const value = input?.value;

	if (validation) {
		const { required } = validation;
		if (required && !value) {
			return true;
		}
	}
	return false;
};

export const hasAnyFieldError = (inputs: Input[], config?: Configuration | undefined): boolean => {
	let anyFieldHasError = false;

	inputs.forEach((input) => {
		const type = input?.type;
		switch (type) {
			case INPUTS_TYPES.NUMBER:
			case INPUTS_TYPES.CURRENCY:
				if (getHasNumericFieldsError(input)) {
					anyFieldHasError = getHasNumericFieldsError(input);
				}
				if (config && !amountMatchesPriceAndQuantity(config)) {
					anyFieldHasError = !amountMatchesPriceAndQuantity(config);
				}
				break;
			case INPUTS_TYPES.STRING:
				if (getHasStringFieldsError(input)) {
					anyFieldHasError = getHasStringFieldsError(input);
				}
				break;
			case INPUTS_TYPES.DROPDOWN:
				if (requiredFieldHasValue(input)) {
					anyFieldHasError = requiredFieldHasValue(input);
				}
				break;
		}
	});

	return anyFieldHasError;
};

export const amountMatchesPriceAndQuantity = (config: Configuration): boolean => {
	const amountInputValue = config.inputs?.find((input) => input.summaryMapping === SUMMARY_MAPPINGS.AMOUNT)?.value;
	const quantityInputValue = config.inputs?.find((input) => input.summaryMapping === SUMMARY_MAPPINGS.QUANTITY)?.value;
	const revenueCodeInputValue = config.inputs?.find(
		(input) => input.summaryMapping === SUMMARY_MAPPINGS.REVENUE_CODE,
	)?.value;
	let revenueCodePrice = 0;

	const revenueCodeInput = config.inputs?.find((input) => input.summaryMapping === SUMMARY_MAPPINGS.REVENUE_CODE);

	if (revenueCodeInput && revenueCodeInput.type === INPUTS_TYPES.PAYMENT_GROUP) {
		config.paymentGroups?.forEach((group) => {
			const revenueCodeSelection = group.selectionValues?.find(
				(selectionValue) => selectionValue.key === revenueCodeInputValue && selectionValue.hasLineQuantity,
			);
			if (revenueCodeSelection && revenueCodeSelection.defaultFee) {
				revenueCodePrice = revenueCodeSelection.defaultFee;
			}
		});
	} else {
		if (revenueCodeInput) {
			revenueCodeInput.selectionValues?.forEach((selection) => {
				if (selection.key === revenueCodeInputValue && selection.hasLineQuantity && selection.defaultFee) {
					revenueCodePrice = selection.defaultFee;
				}
			});
		}
	}

	if (amountInputValue && quantityInputValue && revenueCodePrice) {
		return Number(amountInputValue) === roundToPrecision(Number(quantityInputValue) * Number(revenueCodePrice));
	}
	return true;
};
