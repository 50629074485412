import { Button, ButtonProps } from '@mui/material';
import { common } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

interface StyledButtonProps extends ButtonProps {
	footerHasBackground?: boolean;
}

export const StyledButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== 'footerHasBackground',
})<StyledButtonProps>(({ theme, footerHasBackground }) => ({
	background: footerHasBackground ? theme.palette.secondary.contrastText : theme.palette.primary.light,
	display: 'flex',
	color: footerHasBackground ? theme.palette.secondary.main : common.white,
	alignItems: 'center',
	justifyContent: 'center',
	boxShadow: 'rgba(50, 50, 93, 0.14902) 2px 1px 9px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px',
	height: 'min(45px, 4.5vh)',
	fontSize: '15px',
	padding: '0 45px',
	margin: '0px 20px',
	borderRadius: '8px',
	'&:disabled': {
		color: 'rgba(0, 0, 0, 0.87)',
		opacity: '0.4',
		cursor: 'not-allowed',
	},
	'	&:hover': {
		background: footerHasBackground ? theme.palette.secondary.contrastText : theme.palette.primary.light,
	},
	'&:focus': {
		outline: theme.palette.primary.main,
	},
	'& svg': {
		verticalAlign: 'middle',
		width: 'min(30px, 3vh)',
		height: 'min(22px, 2.2vh)',
		marginRight: '12px',
		marginBottom: '2px',
	},
	[theme.breakpoints.down('md')]: {
		margin: '10px 0px',
		padding: '3%',
	},
}));
