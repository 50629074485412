import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface HeaderTextProps extends TypographyProps {
	hasBackground?: boolean;
}

export const HeaderText = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'hasBackground',
})<HeaderTextProps>(({ theme, hasBackground }) => ({
	fontWeight: 700,
	marginLeft: theme.spacing(4),
	fontSize: 25,
	maxWidth: '60%',
	marginTop: theme.spacing(2),
	color: hasBackground ? theme.palette.getContrastText(theme.palette.secondary.main) : theme.palette.text.primary,
	[theme.breakpoints.down('sm')]: {
		fontSize: 15,
		marginLeft: theme.spacing(3),
	},
}));
