import { Box, Modal } from '@mui/material';
import { useStripe } from '@stripe/react-stripe-js';
import { PaymentIntentResult } from '@stripe/stripe-js';
import React, { useMemo } from 'react';

import { Loading } from 'components/common';
import { useFetcher } from 'hooks';

import { StripePaymentStatus } from './types/paymentSystem';
import { PaymentConfirmation } from '../PaymentFeedback/PaymentConfirmation';
import { PaymentError } from '../PaymentFeedback/PaymentError';
import { PaymentProcessing } from '../PaymentFeedback/PaymentProcessing';

export const StripeBankPaymentConfirmation: React.FunctionComponent<{
	paymentIntentClientSecret: string;
}> = ({ paymentIntentClientSecret }) => {
	const stripe = useStripe();

	const getPaymentIntentData = useMemo(() => {
		if (stripe) {
			return () => {
				return stripe.retrievePaymentIntent(paymentIntentClientSecret);
			};
		}
	}, [paymentIntentClientSecret, stripe]);

	const [paymentIntentData, isLoading] = useFetcher<PaymentIntentResult | undefined>(getPaymentIntentData, undefined);

	if (paymentIntentData && !isLoading) {
		const { paymentIntent } = paymentIntentData;
		if (paymentIntent) {
			switch (paymentIntent.status) {
				case StripePaymentStatus.SUCCEEDED:
					return (
						<Modal open>
							<PaymentConfirmation />
						</Modal>
					);

				case StripePaymentStatus.REQUIRES_PAYMENT_METHOD:
					return (
						<Modal open>
							<PaymentError />
						</Modal>
					);

				default:
					return (
						<Modal open>
							<PaymentProcessing />
						</Modal>
					);
			}
		}
	}
	return (
		<Modal open>
			<Box
				component="div"
				sx={{
					position: 'fixed',
					width: '100vw',
					zIndex: 99,
					background: 'rgba(255, 255, 255, 0.7)',
					height: '100vh',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
				}}
			>
				<Loading />
			</Box>
		</Modal>
	);
};
