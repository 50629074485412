import { common } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

export const StyledStripeInputWrapper = styled('div')(({ theme }) => ({
	display: 'inline-block',
	width: ' 100%',
	padding: '5px 10px 10px 10px',
	position: 'relative',
	[theme.breakpoints.down('sm')]: {
		padding: '5px 10px 5px 10px',
	},
	'& .StripeElement': {
		padding: '8px',
		border: '1px solid rgba(33, 48, 66, 0.1)',
		boxShadow: 'rgba(50, 50, 93, 0.14902) 1px 1px 4px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px',
		borderRadius: '8px',
		margin: '3px 10px',
		transition: ' box-shadow 0.3s, border 0.3s',
		backgroundColor: common.white,
		width: '100%',
		'&:-webkit-autofill': {
			background: theme.palette.primary.dark,
		},
	},

	'& .StripeElement--invalid': {
		borderColor: theme.palette.error.main,
	},

	'&.hasCustomIcon': {
		'	&::before': {
			position: 'absolute',
			bottom: '31px',
			left: '28px',
			height: '15px',
			width: '15px',
		},
		'& > .StripeElement': {
			paddingLeft: '30px',
		},
		'& > .stripe-input': {
			paddingLeft: '30px',
		},
	},
}));
