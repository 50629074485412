import Divider from '@mui/material/Divider';
import React from 'react';
import { useIntl } from 'react-intl';

import { StyledContainer } from 'components/features/PaymentFeedback/StyledContainer';

import { PaymentLayout } from './PaymentLayout';
import confirmationImage from '../../../theme/assets/images/payment_confirmation.svg';
import { StyledAdditionalMessage } from '../PaymentFeedback/StyledAdditionalMessage';
import { StyledImage } from '../PaymentFeedback/StyledImage';
import { StyledSubtitle } from '../PaymentFeedback/StyledSubtitle';

export const PaymentInitiated = (): JSX.Element => {
	const intl = useIntl();

	const processingMessage = intl.formatMessage({ id: 'payment-initiated__notify-message' });
	const title = intl.formatMessage({ id: 'payment-initiated__title' });

	return (
		<PaymentLayout>
			<StyledContainer>
				<StyledImage src={confirmationImage} alt="Payment initiated" />
				<StyledSubtitle color="primary">{title}</StyledSubtitle>
				<Divider flexItem sx={{ minHeight: 1 }} />
				<StyledAdditionalMessage variant="body2" color="primary" align="center">
					{processingMessage}
				</StyledAdditionalMessage>
			</StyledContainer>
		</PaymentLayout>
	);
};
