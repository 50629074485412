import React, { Suspense } from 'react';

import { Loading, AppWrapper } from 'components/common';
import { ConfigurationProvider, PaymentProvider } from 'components/features';
import { ThemeWrapper } from 'theme/ThemeWrapper';

const App = () => {
	return (
		<ThemeWrapper>
			<Suspense fallback={<Loading />}>
				<ConfigurationProvider fallback={<Loading />}>
					<PaymentProvider>
						<AppWrapper />
					</PaymentProvider>
				</ConfigurationProvider>
			</Suspense>
		</ThemeWrapper>
	);
};

export default App;
