import CancelIcon from '@mui/icons-material/Cancel';
import PaymentIcon from '@mui/icons-material/Payment';
import { Alert, Box, Modal, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { common } from '@mui/material/colors';
import {
	useStripe,
	useElements,
	PaymentElement,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
} from '@stripe/react-stripe-js';
import {
	StripeError,
	StripePaymentElementOptions,
	StripeElementChangeEvent,
	StripePaymentElementChangeEvent,
	PaymentMethod,
} from '@stripe/stripe-js';
import classNames from 'classnames';
import React, { FunctionComponent, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useIntl } from 'react-intl';

import { Loading } from 'components/common';
import { Timer } from 'components/common/Timer/Timer';
import { formatExternalUrl } from 'helpers';
import { useFetcher } from 'hooks';

import { cancelPaymentSession } from './actions/cancelPaymentSession';
import { confirmPaymentSession } from './actions/confirmPaymentSession';
import { createMotoPaymentSession } from './actions/createMotoPaymentSession';
import { getReturnUrlParams } from './actions/getReturnUrlParams';
import { addChargeFees, revertChargeFees } from './actions/handleChargeFees';
import { initiatePaymentSessionCallback } from './actions/initiatePaymentSession';
import {
	AmountFormatter,
	isDirectDebit,
	mapStripeErrorToTranslationKey,
	roundToPrecision,
	isOpenBanking,
	mapReturnUrlParameters,
} from './helpers';
import { PaymentResult, usePaymentContext } from './PaymentContext';
import { StyledAmount } from './StyledAmount';
import { StyledButton } from './StyledButton';
import { StyledCaptchaContainer } from './StyledCaptchaContainer';
import { StyledCircularProgress } from './StyledCircularProgress';
import { StyledImage } from './StyledImage';
import { StyledModalBody } from './StyledModalBody';
import { StyledModalContent } from './StyledModalContent';
import { StyledModalFooter } from './StyledModalFooter';
import { StyledModalHeader } from './StyledModalHeader';
import { StyledPaymentHeaderTitle } from './StyledPaymentHeaderTitle';
import { StyledStripeInput } from './StyledStripeInput';
import { StyledStripeInputLabel } from './StyledStripeInputLabel';
import { StyledStripeInputWrapper } from './StyledStripeInputWrapper';
import { PaymentSessionCancellation, PaymentSessionConfirmation } from './types/payment';
import { MotoPaymentSession, PaymentSession, PaymentSystemType, StripePaymentSession } from './types/paymentSystem';
import {
	useClientBranding,
	useConfiguration,
	useGetIsDraft,
	useGetIsQuickPayPage,
	usePaymentParameters,
} from '../Configuration/hooks';

const MAXIMUM_ALLOWED_FAILED_ATTEMPTS = 7;
const CAPTCHA_TRIGGER = 3;

const initialCardElementsState = {
	cardNumber: false,
	cardCvc: false,
	cardExpiry: false,
};

const cardElementOptions = {
	style: {
		base: {
			iconColor: '#68B9FF',
			fontSize: '15px',
			color: '#000',
			'::placeholder': {
				color: '#d1d3d4',
			},
		},
	},
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const StripePaymentModal: FunctionComponent<{
	sessionExpired: boolean;
	timeLeft?: number;
	sessionData: PaymentSession;
	sessionCreationLoading: boolean;
	sessionCreationError: Error | null;
}> = ({ sessionExpired, timeLeft, sessionData, sessionCreationLoading, sessionCreationError }) => {
	const [stripePaymentConfirmed, setStripePaymentConfirmed] = useState<boolean>(false);
	const [paymentCancelled, setPaymentCancelled] = useState(false);
	const [cardholderName, setCardholderName] = useState('');
	const [selectedPaymentType, setSelectedPaymentType] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [isFinalAmountCalculated, setIsFinalAmountCalculated] = useState(false);
	const [isFinalAmountCalculating, setIsFinalAmountCalculating] = useState(false);
	const [elementsLoaded, setElementsLoaded] = useState(initialCardElementsState);
	const [isRedirectMessageVisible, displayRedirectMessage] = useState(false);
	const [error, setError] = useState<StripeError | null | undefined>(null);
	const [chargeFeeError, setChargeFeeError] = useState('');
	const [buttonsDisabled, setButtonsDisabled] = useState(false);
	const [paymentConfirmationSend, setPaymentConfirmationSend] = useState(false);
	const [requestReturnUrlParams, setRequestReturnUrlParams] = useState(false);
	const [paymentResultStatus, setPaymentResultStatus] = useState(null as unknown as PaymentResult);
	const [paymentChargeId, setPaymentChargeId] = useState('');
	const [numberOfAttempts, setNumberOfAttempts] = useState(0);
	const [captchaToken, setCaptchaToken] = useState<string | null>(null);
	const [hasFees, setHasFees] = useState(false);
	const errorRef = useRef<HTMLSpanElement>(null);
	const timeRef = useRef<HTMLDivElement>(null);
	const captchaRef = useRef<ReCAPTCHA>(null);
	const siteKey = window.ENV.REACT_APP_CAPTCHA_KEY as string;

	const { returnUrl, payments } = usePaymentParameters();

	// this sets focus on timer when opening stripe modal
	useEffect(() => {
		if (timeRef && timeRef.current) {
			timeRef.current.focus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeRef.current]);

	const { isPaymentModalOpen, onClose, paymentData, updatePayment, onCompletePayment, onError, onCancel, onLoading } =
		usePaymentContext();

	const {
		config: {
			clientID,
			entity,
			paymentSystem: { declineOnCancel, paymentMethods, currency, currencySymbol },
			basicInfo: { country, postalCode },
			amountFormat,
		},
		data: { identifier: paymentId, extraChargeRevenueCode, parameters },
	} = useConfiguration();

	const intl = useIntl();
	const stripe = useStripe();
	const elements = useElements();
	const isQuickPayPage = useGetIsQuickPayPage();

	const revenueCodeDescription = extraChargeRevenueCode
		? payments?.find((paymentParam) => paymentParam.revenueCode === extraChargeRevenueCode.revenueCode)
				?.referenceNumberDescription
		: '';
	const paymentHasRecurrentData =
		parameters.payments?.some(
			(payment) => payment.recurrenceData && payment.recurrenceData?.selectedRecurrence !== 'one_time',
		) || false;

	const {
		logo,
		header,
		footer: { hasBackgroundColor: footerHasBackground },
	} = useClientBranding();
	const isDraft = useGetIsDraft();
	const { text: headerText, hasBackgroundColor: headerHasBackground } = header;
	const amount = roundToPrecision(paymentData.amount * 100, 0); // stripe expects 100 for 1.00

	const areElementsLoaded =
		sessionData && (sessionData.session as StripePaymentSession).isMoto
			? Object.values(elementsLoaded).every((hasLoaded) => hasLoaded)
			: true;

	const updateFailedAttempt = async (): Promise<void> => {
		resetCaptchaToken();
		if (numberOfAttempts < MAXIMUM_ALLOWED_FAILED_ATTEMPTS - 1) {
			setNumberOfAttempts(numberOfAttempts + 1);
		} else {
			await handleCancellation();
		}
	};

	const confirmPaymentSessionAction = useMemo(() => {
		if (stripePaymentConfirmed && sessionData && sessionData.session) {
			const data: StripePaymentSession = {
				type: PaymentSystemType.STRIPE,
				chargeId: (sessionData.session as StripePaymentSession).chargeId,
				amount: sessionData.session.amount as number,
				paymentType: (sessionData.session as StripePaymentSession).paymentType,
			};
			return confirmPaymentSession(paymentId, { session: data });
		}
	}, [paymentId, sessionData, stripePaymentConfirmed]);

	const [sessionConfirmationData, sessionConfirmationLoading, sessionConfirmationError] =
		useFetcher<PaymentSessionConfirmation | null>(confirmPaymentSessionAction, null);

	const cancelPaymentSessionAction = useMemo(() => {
		if (paymentCancelled) {
			const isAttemptLimitReached = numberOfAttempts >= MAXIMUM_ALLOWED_FAILED_ATTEMPTS - 1;
			return cancelPaymentSession(paymentId, isDraft, isAttemptLimitReached);
		}
	}, [paymentCancelled, paymentId, isDraft, numberOfAttempts]);

	const [sessionCancelData, sessionCancelLoading, sessionCancelError] = useFetcher<PaymentSessionCancellation | null>(
		cancelPaymentSessionAction,
		null,
	);

	const revertFinalAmountCalculation = useCallback(async () => {
		setIsFinalAmountCalculating(true);
		const data: PaymentSession = {
			session: {
				type: PaymentSystemType.STRIPE,
				isMoto: (sessionData.session as StripePaymentSession).isMoto,
			},
			email: paymentData.email,
			address: paymentData.address,
		};
		const revertedAmount = await revertChargeFees(paymentId, data);
		updatePayment({ ...paymentData, amount: revertedAmount });
		setIsFinalAmountCalculated(false);
		setIsFinalAmountCalculating(false);
	}, [paymentData, paymentId, updatePayment, sessionData]);

	useEffect(() => {
		const paymentElement = elements?.getElement('payment');

		if (paymentElement) {
			paymentElement?.on('change', async (event: StripePaymentElementChangeEvent) => {
				setSelectedPaymentType(event.value.type);
				if (
					event.value.type === 'card' &&
					isFinalAmountCalculated &&
					extraChargeRevenueCode &&
					!event.complete &&
					!paymentHasRecurrentData
				) {
					revertFinalAmountCalculation();
				}
			});
		}
	}, [elements]);

	const onFocus = () => {
		setError(null);
	};

	const onReady = (name: string) => () => {
		setElementsLoaded((prev) => ({ ...prev, [name]: true }));
	};
	const onChange = async (event: StripeElementChangeEvent) => {
		if (
			event.elementType === 'cardNumber' &&
			extraChargeRevenueCode &&
			isFinalAmountCalculated &&
			!paymentHasRecurrentData
		) {
			revertFinalAmountCalculation();
		}
		setError(event.error);
	};

	const handleCancellation = useCallback(
		async (evt?: React.MouseEvent<HTMLButtonElement, MouseEvent>, isSessionExpired?: boolean) => {
			evt?.preventDefault();
			if (isQuickPayPage) {
				if (declineOnCancel) {
					setPaymentCancelled(true);
					setError(null);
					setSubmitted(false);
					setButtonsDisabled(false);
					await onCancel(undefined, isSessionExpired);
					onClose();
					displayRedirectMessage(true);
				} else {
					onClose();
				}
				if (isFinalAmountCalculated) {
					revertFinalAmountCalculation();
				}
			} else {
				setPaymentCancelled(true);
			}
		},
		[declineOnCancel, isQuickPayPage, onClose, onCancel, isFinalAmountCalculated, revertFinalAmountCalculation],
	);

	const getReturnUrl = (params?: Record<string, string | number>) => {
		if (isOpenBanking(selectedPaymentType)) {
			if (returnUrl && returnUrl !== '/') {
				return params ? formatExternalUrl(returnUrl, params) : returnUrl;
			}
			if (isQuickPayPage) {
				return `${window.location.origin}/payments?client=${clientID}&entity=${entity}`;
			}
			return `${window.location.origin}/?client=${clientID}&entity=${entity}`;
		}
		if (isDirectDebit(selectedPaymentType)) {
			return `${window.location.origin}/initiated`;
		}
		return undefined;
	};

	const onSubmit = async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		// if config is draft do not send confirmation request
		if (isDraft) {
			return;
		}

		evt.preventDefault();

		if (!stripe || !elements) {
			return;
		}
		setButtonsDisabled(true);

		await initiatePaymentSessionCallback(paymentId, selectedPaymentType);

		if (sessionData) {
			setPaymentConfirmationSend(true);
			if ((sessionData.session as StripePaymentSession).isMoto) {
				setSubmitted(true);

				if (!cardholderName) {
					setPaymentConfirmationSend(false);
					setButtonsDisabled(false);
					return;
				}

				const cardNumberElement = elements && elements.getElement(CardNumberElement);

				if (cardNumberElement) {
					try {
						const payload = await stripe.createPaymentMethod({
							type: 'card',
							card: cardNumberElement,
							billing_details: {
								name: cardholderName,
							},
						});

						if (payload.error || !payload.paymentMethod) {
							setError(payload.error);
							updateFailedAttempt();
							setPaymentConfirmationSend(false);
							setSubmitted(false);
							setButtonsDisabled(false);
							return;
						}
						const data: MotoPaymentSession = {
							session: {
								type: PaymentSystemType.STRIPE,
								amount,
							},
							email: paymentData.email,
							address: paymentData.address,
							paymentMethod: payload.paymentMethod.id,
						};
						const moto = await createMotoPaymentSession(paymentId, data, isDraft);
						setPaymentChargeId(moto);
						setStripePaymentConfirmed(true);
						setError(null);
						onClose();
					} catch (error) {
						setError(error as StripeError);
						updateFailedAttempt();
						setPaymentConfirmationSend(false);
						setSubmitted(false);
						setButtonsDisabled(false);
					}
				}
			} else {
				const { error: submitError } = await elements.submit();
				if (submitError) {
					setPaymentConfirmationSend(false);
					setSubmitted(false);
					setButtonsDisabled(false);
					setError(submitError);
					updateFailedAttempt();
					return;
				}

				let payload;
				if (isDirectDebit(selectedPaymentType) || isOpenBanking(selectedPaymentType)) {
					const returnUrlParams = isOpenBanking(selectedPaymentType) ? await getReturnUrlParams(paymentId)() : null;
					const mappedParams = returnUrlParams && mapReturnUrlParameters(returnUrlParams, true);
					const returnUrl = mappedParams ? getReturnUrl(mappedParams) : getReturnUrl();

					payload = await stripe.confirmPayment({
						elements,
						confirmParams: {
							return_url: returnUrl ?? '',
							payment_method_data: {
								billing_details: {
									address: {
										country,
										postal_code: postalCode,
									},
								},
							},
						},
					});
				} else {
					payload = await stripe.confirmPayment({
						elements,
						confirmParams: {
							return_url: getReturnUrl(),
							payment_method_data: {
								billing_details: {
									address: {
										country,
										postal_code: postalCode,
									},
								},
							},
						},
						// Uncomment below if you only want redirect for redirect-based payments
						redirect: 'if_required',
					});
				}

				if (payload.error) {
					setError(payload.error);
					updateFailedAttempt();
					setPaymentConfirmationSend(false);
					setButtonsDisabled(false);
				} else {
					if (!isDirectDebit(selectedPaymentType) || !isOpenBanking(selectedPaymentType)) {
						setStripePaymentConfirmed(true);
					}
					setError(null);
					onClose();
				}
			}
		} else {
			setButtonsDisabled(false);
		}
	};

	const calculateFinalAmount = async () => {
		if (!stripe || !elements) {
			return;
		}
		setIsFinalAmountCalculating(true);
		if (extraChargeRevenueCode && !isFinalAmountCalculated) {
			let paymentMethod: PaymentMethod | undefined = undefined;
			if ((sessionData.session as StripePaymentSession).isMoto) {
				const cardNumberElement = elements && elements.getElement(CardNumberElement);

				if (cardNumberElement) {
					const result = await stripe.createPaymentMethod({
						type: 'card',
						card: cardNumberElement,
						billing_details: {
							name: cardholderName,
						},
					});

					if (result.error) {
						setError(result.error);
						setIsFinalAmountCalculating(false);
						return;
					}

					paymentMethod = result.paymentMethod;
				}
			} else if (selectedPaymentType === 'card') {
				const { error: submitError } = await elements.submit();
				if (submitError) {
					setError(submitError);
					setIsFinalAmountCalculating(false);
					return;
				}

				const result = await stripe.createPaymentMethod({
					elements,
					params: {
						billing_details: {
							address: {
								country,
								postal_code: postalCode,
							},
						},
					},
				});
				paymentMethod = result.paymentMethod;
			}
			if (paymentMethod?.card?.funding === 'credit') {
				const data: PaymentSession = {
					session: {
						type: PaymentSystemType.STRIPE,
						amount,
						isMoto: (sessionData.session as StripePaymentSession).isMoto,
					},
					email: paymentData.email,
					address: paymentData.address,
					paymentMethodId: paymentMethod?.id,
				};

				try {
					const finalAmount = await addChargeFees(paymentId, data);
					updatePayment({ ...paymentData, amount: finalAmount });
					setIsFinalAmountCalculated(true);
					setHasFees(finalAmount > paymentData.amount);
				} catch (error) {
					setChargeFeeError(error as string);
				}
				setIsFinalAmountCalculating(false);
				return;
			} else {
				setIsFinalAmountCalculated(true);
				setIsFinalAmountCalculating(false);
			}
		}
	};

	useEffect(() => {
		if (
			selectedPaymentType !== 'card' &&
			selectedPaymentType &&
			extraChargeRevenueCode &&
			isFinalAmountCalculated &&
			!paymentHasRecurrentData
		) {
			revertFinalAmountCalculation();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPaymentType, extraChargeRevenueCode, isFinalAmountCalculated, paymentId, isDraft]);

	useEffect(() => {
		const unloadCallback = (event: { preventDefault: () => void; returnValue: string }) => {
			event.preventDefault();
			if (!sessionExpired) {
				event.returnValue = '';
			}
			return '';
		};
		if (
			sessionCreationLoading ||
			sessionConfirmationLoading ||
			sessionCancelLoading ||
			(isPaymentModalOpen &&
				!paymentCancelled &&
				!sessionCreationError &&
				!sessionCancelError &&
				!sessionConfirmationError)
		) {
			window.addEventListener('beforeunload', unloadCallback);
		} else {
			window.removeEventListener('beforeunload', unloadCallback);
		}
		return () => window.removeEventListener('beforeunload', unloadCallback);
	}, [
		sessionCreationLoading,
		sessionConfirmationLoading,
		sessionCancelLoading,
		isPaymentModalOpen,
		sessionExpired,
		paymentCancelled,
		sessionCreationError,
		sessionCancelError,
		sessionConfirmationError,
	]);

	useEffect(() => {
		if (sessionExpired) {
			handleCancellation(undefined, sessionExpired);
		}
	}, [sessionExpired, handleCancellation, onError, intl]);

	useEffect(() => {
		if (isQuickPayPage && sessionExpired && paymentCancelled && !sessionCancelLoading) {
			onError(new Error(intl.formatMessage({ id: 'stripe-error__session-expired' })));
			return;
		}
		if (
			isQuickPayPage &&
			!sessionExpired &&
			paymentCancelled &&
			!sessionCancelLoading &&
			numberOfAttempts === MAXIMUM_ALLOWED_FAILED_ATTEMPTS - 1
		) {
			onError(new Error(intl.formatMessage({ id: 'stripe-error__limit-reached' })));
		}
	}, [sessionExpired, paymentCancelled, sessionCancelLoading, intl, onError, isQuickPayPage, numberOfAttempts]);

	useEffect(() => {
		if (!isPaymentModalOpen) {
			setElementsLoaded(initialCardElementsState);
		}
	}, [isPaymentModalOpen, amount, handleCancellation, onError]);

	const getReturnUrlParamsAction = useMemo(() => {
		if (requestReturnUrlParams) {
			return getReturnUrlParams(paymentId);
		}
	}, [paymentId, requestReturnUrlParams]);

	const [returnUrlParamsData] = useFetcher<Record<string, string | number> | null>(getReturnUrlParamsAction, null);

	useEffect(() => {
		if (!isQuickPayPage && !!returnUrlParamsData) {
			switch (paymentResultStatus) {
				case PaymentResult.COMPLETED: {
					onCompletePayment(returnUrlParamsData || {});
					setCardholderName('');
					displayRedirectMessage(true);
					break;
				}
				case PaymentResult.CANCELLED: {
					if (declineOnCancel) {
						setError(null);
						setButtonsDisabled(false);
						setSubmitted(false);
						displayRedirectMessage(true);
						onCancel(returnUrlParamsData || {}, sessionExpired);
					} else {
						onClose();
					}
					break;
				}
				case PaymentResult.FAILED: {
					onError(sessionCreationError || sessionConfirmationError || sessionCancelError, returnUrlParamsData || {});
					displayRedirectMessage(true);
					break;
				}
			}
		}
	}, [
		returnUrlParamsData,
		isQuickPayPage,
		onCompletePayment,
		sessionCreationError,
		sessionConfirmationError,
		sessionCancelError,
		onCancel,
		onError,
		paymentResultStatus,
		declineOnCancel,
		onClose,
		sessionExpired,
	]);

	useEffect(() => {
		if (sessionCancelData) {
			if (isQuickPayPage) {
				setPaymentCancelled(false);
				onCancel();
				setCardholderName('');
				setSubmitted(false);
				setButtonsDisabled(false);
			} else {
				onClose();
				setRequestReturnUrlParams(true);
				setPaymentResultStatus(PaymentResult.CANCELLED);
			}
		}
	}, [sessionCancelData, onCancel, isQuickPayPage, onClose]);

	useEffect(() => {
		if (sessionConfirmationData) {
			if (isQuickPayPage) {
				onCompletePayment(sessionConfirmationData?.returnUrlParameters);
				setCardholderName('');
			} else {
				setRequestReturnUrlParams(true);
				setPaymentResultStatus(PaymentResult.COMPLETED);
			}
		}
	}, [sessionConfirmationData, onCompletePayment, isQuickPayPage]);

	useEffect(() => {
		if (sessionCreationError || sessionConfirmationError || sessionCancelError) {
			if (isQuickPayPage) {
				onError(sessionCreationError || sessionConfirmationError || sessionCancelError);
			} else {
				setRequestReturnUrlParams(true);
				setPaymentResultStatus(PaymentResult.FAILED);
			}
		}
	}, [sessionCreationError, sessionConfirmationError, sessionCancelError, onError, isQuickPayPage]);

	useEffect(() => {
		onLoading(sessionCreationLoading || sessionConfirmationLoading || sessionCancelLoading);
	}, [sessionCreationLoading, sessionConfirmationLoading, sessionCancelLoading, onLoading]);

	useEffect(() => {
		if (error && error.type !== 'validation_error') {
			errorRef.current?.scrollIntoView();
		}
	}, [error]);

	const handleCaptchaChange = useCallback((token: string | null) => setCaptchaToken(token), []);

	const resetCaptchaToken = useCallback(() => {
		setCaptchaToken(null);
		captchaRef.current?.reset();
	}, []);

	const paymentElementOptions: StripePaymentElementOptions = {
		layout: 'tabs',
		wallets: {
			googlePay: paymentMethods?.wallets ? 'auto' : 'never',
			applePay: paymentMethods?.wallets ? 'auto' : 'never',
		},
		fields: {
			billingDetails: {
				address: {
					country: 'never',
					postalCode: 'never',
				},
			},
		},
		defaultValues: {
			billingDetails: {
				email: paymentData?.email,
			},
		},
	};

	const renderPayButton = () => {
		if ((sessionData.session as StripePaymentSession).isMoto) {
			if (extraChargeRevenueCode && !isFinalAmountCalculated && !paymentHasRecurrentData) {
				return (
					<StyledButton
						onClick={calculateFinalAmount}
						disabled={isFinalAmountCalculating || isDraft}
						aria-disabled={isFinalAmountCalculating || isDraft ? true : false}
						footerHasBackground={footerHasBackground}
					>
						{intl.formatMessage({ id: 'button__calculate__final__amount' })}
						{isFinalAmountCalculating && <StyledCircularProgress size={22} />}
					</StyledButton>
				);
			}
		} else if (
			extraChargeRevenueCode &&
			(selectedPaymentType === '' || selectedPaymentType === 'card') &&
			!isFinalAmountCalculated &&
			!paymentHasRecurrentData
		) {
			return (
				<StyledButton
					onClick={calculateFinalAmount}
					disabled={isFinalAmountCalculating || isDraft}
					aria-disabled={isFinalAmountCalculating || isDraft ? true : false}
					footerHasBackground={footerHasBackground}
				>
					{intl.formatMessage({ id: 'button__calculate__final__amount' })}
					{isFinalAmountCalculating && <StyledCircularProgress size={22} />}
				</StyledButton>
			);
		}
		return (
			<StyledButton
				onClick={onSubmit}
				disabled={
					paymentConfirmationSend ||
					buttonsDisabled ||
					isFinalAmountCalculating ||
					isDraft ||
					(numberOfAttempts >= CAPTCHA_TRIGGER && !captchaToken)
				}
				aria-disabled={
					paymentConfirmationSend ||
					buttonsDisabled ||
					isFinalAmountCalculating ||
					isDraft ||
					(numberOfAttempts >= CAPTCHA_TRIGGER && !captchaToken)
						? true
						: false
				}
				footerHasBackground={footerHasBackground}
			>
				{intl.formatMessage({ id: 'button__pay' })} <PaymentIcon />
				{paymentConfirmationSend && <StyledCircularProgress size={22} />}
			</StyledButton>
		);
	};
	const processingMessage = (sessionData.session as StripePaymentSession).isMoto
		? intl.formatMessage({ id: 'payment-processing__message-moto' }, { chargeId: paymentChargeId })
		: intl.formatMessage({ id: 'payment-processing__message' });

	return (
		<>
			<Modal
				open={isPaymentModalOpen}
				slots={{
					backdrop: Backdrop,
				}}
				slotProps={{
					backdrop: {
						sx: {
							zIndex: -1,
						},
					},
				}}
				sx={(theme) => ({
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					minWidth: '600px',
					[theme.breakpoints.down('sm')]: {
						minWidth: '100%',
					},
				})}
			>
				<>
					<StyledModalContent>
						{(sessionCreationLoading || !areElementsLoaded) && (
							<Box
								component="div"
								sx={{
									position: 'fixed',
									width: '100vw',
									zIndex: 99,
									background: 'rgba(255, 255, 255, 0.7)',
									height: '100vh',
									top: 0,
									left: 0,
									right: 0,
									bottom: 0,
								}}
							>
								<Loading />
							</Box>
						)}

						{!sessionCreationLoading && (
							<>
								<StyledModalHeader role="banner" headerHasBackground={headerHasBackground}>
									<Box component="div" sx={{ textAlign: 'center' }}>
										<Box
											component="div"
											sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}
										>
											<StyledImage src={logo} alt="Company Logo" role="presentation" />
											<StyledPaymentHeaderTitle headerHasBackground={headerHasBackground}>
												{headerText}
											</StyledPaymentHeaderTitle>
										</Box>
									</Box>
								</StyledModalHeader>
								{isDraft && (
									<Typography
										aria-live="polite"
										role="alert"
										sx={(theme) => ({ color: theme.palette.error.main, alignSelf: 'center', fontSize: '1em' })}
									>
										{intl.formatMessage({ id: 'stripe-error__draft-session' })}
									</Typography>
								)}
								<div ref={timeRef} tabIndex={0}>
									<Timer timeLeft={timeLeft} />
								</div>
								<StyledModalBody>
									<Box
										component="div"
										role="main"
										sx={(theme) => ({
											margin: '-0px 0',
											width: '100%',
											display: 'flex',
											flexDirection: 'column',
											[theme.breakpoints.down('sm')]: {
												justifyContent: 'space-between',
											},
										})}
									>
										{chargeFeeError && (
											<>
												<Typography
													component="span"
													sx={(theme) => ({ alignSelf: 'center', fontSize: 14, color: theme.palette.error.main })}
												>
													{intl.formatMessage({ id: 'stripe-payment__applying-fees-unsuccessful' })}
												</Typography>
											</>
										)}
										{error && error.type !== 'validation_error' && (
											<>
												<Typography
													component="span"
													ref={errorRef}
													sx={(theme) => ({
														alignSelf: 'center',
														fontSize: 18,
														fontWeight: 400,
														color: theme.palette.error.main,
													})}
												>
													{intl.formatMessage({ id: 'stripe-error__title' })}
												</Typography>
												<Typography
													component="span"
													aria-live="polite"
													role="alert"
													sx={(theme) => ({ alignSelf: 'center', fontSize: 14, color: theme.palette.error.main })}
												>
													{intl.formatMessage({ id: mapStripeErrorToTranslationKey(error) })}
												</Typography>
											</>
										)}
										<Box
											component="div"
											sx={(theme) => ({
												display: 'flex',
												flexDirection: 'column',
												flexShrink: 0,
												alignSelf: 'center',
												width: '330px',
												maxWidth: '100%',
												[theme.breakpoints.down('sm')]: {
													flexGrow: 2,
													justifyContent: 'center',
												},
											})}
										>
											<StyledAmount data-test="card-details-amount">
												{AmountFormatter(currency, currencySymbol, amountFormat).format(paymentData.amount)}
											</StyledAmount>
											{extraChargeRevenueCode && !paymentHasRecurrentData && (
												<>
													{isFinalAmountCalculated && hasFees && (
														<Alert severity="warning">
															{intl.formatMessage(
																{ id: 'stripe-payment__payment-card-fee-added' },
																{ revenueCodeDescription },
															)}
														</Alert>
													)}
													{!isFinalAmountCalculated && (
														<Alert severity="warning">
															{intl.formatMessage(
																{ id: 'stripe-payment__payment-commercial-credit-card-fee-warning' },
																{ revenueCodeDescription },
															)}
														</Alert>
													)}
												</>
											)}
											<Box
												component="div"
												sx={(theme) => ({
													fontSize: '16px',
													margin: theme.spacing(4, 0, 1, 0),
													lineHeight: ' 2.2',
													fontWeight: 'bold',
												})}
											>
												{intl.formatMessage({ id: 'stripe-payment__title' })}
											</Box>
										</Box>
										<Box component="div" sx={{ alignSelf: 'center', width: '330px', maxWidth: '100%' }}>
											{(sessionData.session as StripePaymentSession).isMoto ? (
												<>
													<StyledStripeInputWrapper className={classNames({ hasCustomIcon: true })}>
														<StyledStripeInputLabel htmlFor="cardholderName">
															{intl.formatMessage({ id: 'stripe-payment__card-holder' })}
														</StyledStripeInputLabel>
														<StyledStripeInput
															id="cardholderName"
															autoFocus
															onChange={(evt) => {
																setCardholderName(evt.target.value);
															}}
															onFocus={onFocus}
															className={classNames({
																stripeInputError: submitted && !cardholderName,
															})}
															value={cardholderName}
															aria-label="Cardholder name"
															placeholder="Name Surname"
															type="text"
														/>
													</StyledStripeInputWrapper>
													<StyledStripeInputWrapper>
														<StyledStripeInputLabel htmlFor="card-number-element">
															{intl.formatMessage({ id: 'stripe-payment__card-nr' })}
														</StyledStripeInputLabel>
														<CardNumberElement
															onChange={onChange}
															id="card-number-element"
															onFocus={onFocus}
															// eslint-disable-next-line @typescript-eslint/no-explicit-any
															options={{ ...cardElementOptions, showIcon: true, iconStyle: 'solid' as any }}
															onReady={onReady('cardNumber')}
														/>
													</StyledStripeInputWrapper>
													<StyledStripeInputWrapper className={classNames({ hasCustomIcon: true })}>
														<StyledStripeInputLabel htmlFor="card-expiry-element">
															{intl.formatMessage({ id: 'stripe-payment__exp-date' })}
														</StyledStripeInputLabel>
														<CardExpiryElement
															onChange={onChange}
															onFocus={onFocus}
															id="card-expiry-element"
															options={cardElementOptions}
															onReady={onReady('cardExpiry')}
														/>
													</StyledStripeInputWrapper>
													<StyledStripeInputWrapper className={classNames({ hasCustomIcon: true })}>
														<StyledStripeInputLabel htmlFor="card-cvc-element">
															{intl.formatMessage({ id: 'stripe-payment__cvc' })}
														</StyledStripeInputLabel>
														<CardCvcElement
															onChange={onChange}
															id="card-cvc-element"
															onFocus={onFocus}
															options={cardElementOptions}
															onReady={onReady('cardCvc')}
														/>
													</StyledStripeInputWrapper>
												</>
											) : (
												<PaymentElement options={paymentElementOptions} />
											)}
										</Box>
									</Box>
								</StyledModalBody>
								{numberOfAttempts >= CAPTCHA_TRIGGER && (
									<StyledCaptchaContainer>
										<Box
											component="div"
											sx={(theme) => ({
												margin: '-0px 0',
												display: 'flex',
												flexDirection: 'column',
												[theme.breakpoints.down('sm')]: {
													justifyContent: 'space-between',
												},
											})}
										>
											<ReCAPTCHA
												ref={captchaRef}
												sitekey={siteKey}
												onChange={handleCaptchaChange}
												onExpired={resetCaptchaToken}
											/>
										</Box>
									</StyledCaptchaContainer>
								)}
								<StyledModalFooter role="contentinfo" footerHasBackground={footerHasBackground}>
									<Box
										component="div"
										sx={(theme) => ({
											display: 'flex',
											width: '100%',
											justifyContent: 'center',
											[theme.breakpoints.down('sm')]: {
												flexDirection: 'column',
											},
										})}
									>
										<StyledButton
											onClick={handleCancellation}
											disabled={sessionCancelLoading || buttonsDisabled}
											aria-disabled={sessionCancelLoading || buttonsDisabled ? true : false}
											aria-label="cancel"
											footerHasBackground={footerHasBackground}
										>
											{intl.formatMessage({ id: 'button__cancel' })}
											<CancelIcon />
										</StyledButton>
										{renderPayButton()}
									</Box>
								</StyledModalFooter>
							</>
						)}
					</StyledModalContent>
				</>
			</Modal>
			<Backdrop
				open={(!isPaymentModalOpen && paymentConfirmationSend) || (isRedirectMessageVisible && !isQuickPayPage)}
				sx={{
					zIndex: 1,
					color: common.white,
					padding: '0 20px',
				}}
			>
				<Typography variant="h5" role="status">
					{isRedirectMessageVisible
						? intl.formatMessage({ id: 'xpay-cancel-payment-redirect_message' })
						: processingMessage}
				</Typography>
			</Backdrop>
		</>
	);
};
