import { fetchWrapper, RequestMethod } from 'helpers';

import { PaymentSession } from '../types/paymentSystem';

export const createMotoPaymentSession = async (
	paymentId: string,
	body: PaymentSession,
	isDraft: boolean,
): Promise<string> =>
	fetchWrapper<string>(`/payments/${paymentId}/moto${isDraft ? '/draft' : ''}`, {
		method: RequestMethod.POST,
		body,
	});
