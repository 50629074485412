import { DRAFT_ROUTE } from 'components/features/Configuration/constants';

export const getApiUrl = (path: string): string => {
	const url = window.location.pathname;

	return !url.includes(DRAFT_ROUTE) ? path : `${path}${DRAFT_ROUTE}`;
};

export const QUICKPAY_BASE_ENDPOINT = getApiUrl('/configuration');
export const SAVE_INITIAL_PARAMETERS_ENDPOINT = getApiUrl('/payments');
export const SAVE_INITIAL_PARAMETERS_QUICKPAY_ENDOPOINT = getApiUrl('/quick-payments');
