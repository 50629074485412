import { Box } from '@mui/material';
import React from 'react';

import { FooterContainer } from './FooterContainer';
import { FooterLink } from './FooterLink';
import { FooterLogo } from './FooterLogo';
import { FooterParagraph } from './FooterParagraph';
import { LinkContainer } from './LinkContainer';

export const Footer: React.FunctionComponent<{
	logo?: string;
	text?: string[] | string;
	links?: { label: string; link: string }[];
	hasBackground?: boolean;
}> = (props) => {
	const { logo, text, links } = props;
	const textParagraphs = typeof text === 'string' ? [text] : text;

	return (
		<FooterContainer component="footer" hasBackground={props.hasBackground}>
			{logo && <FooterLogo src={logo} alt="Company Logo Footer" role="presentation" />}
			<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
				{textParagraphs &&
					textParagraphs.map((message, index) => (
						<FooterParagraph
							variant="body2"
							color="primary"
							align="center"
							key={index}
							hasBackground={props.hasBackground}
						>
							{message}
						</FooterParagraph>
					))}
				<LinkContainer>
					{links &&
						links.map(({ label, link }) => (
							<FooterLink href={link} key={label} hasBackground={props.hasBackground}>
								{label}
							</FooterLink>
						))}
				</LinkContainer>
			</Box>
		</FooterContainer>
	);
};
