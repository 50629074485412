import { Configuration } from 'components/features/Configuration/types';

import { Input } from '../types/types';

const getHelperTextBySelection = (config: Configuration, input: Input): string => {
	const helperTextDisplayFrom = input.helperText.displayFromMapping;
	const helperTextFromInput = config.inputs?.find((input) => input.summaryMapping === helperTextDisplayFrom);
	const helperTextSelectionValue = helperTextFromInput?.value;
	const selectedHelperText = input.helperText.values?.find((value) => value.key === helperTextSelectionValue);
	return helperTextFromInput && helperTextSelectionValue && selectedHelperText
		? selectedHelperText?.value
		: input.helperText.text || '';
};

export const getInputHelperText = (config: Configuration, input: Input): string => {
	if (input.helperText && input.helperText.text && !input.helperText.displayCustom) {
		return input.helperText.text;
	} else if (input.helperText && input.helperText.displayCustom) {
		return getHelperTextBySelection(config, input);
	}
	return '';
};

const getLabelTextBySelection = (config: Configuration, input: Input): string => {
	const labelTextDisplayFrom = input.labelText?.displayFromMapping;
	if (labelTextDisplayFrom) {
		const labelTextFromInput = config.inputs?.find((input) => input.summaryMapping === labelTextDisplayFrom);
		const labelTextSelectionValue = labelTextFromInput?.value;
		const selectedLabelText = input.labelText?.values?.find((value) => value.key === labelTextSelectionValue);
		return labelTextFromInput && labelTextSelectionValue && selectedLabelText
			? selectedLabelText?.value
			: input.label || '';
	}
	return '';
};

export const getInputLabelText = (config: Configuration, input: Input): string => {
	if (input.labelText && input.labelText.displayCustom) {
		return getLabelTextBySelection(config, input);
	} else if (input.label) {
		return input.label;
	}
	return '';
};
