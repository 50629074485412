import { styled } from '@mui/material/styles';

const midScreenHeight = 706;

export const StyledImage = styled('img')(({ theme }) => ({
	maxWidth: 250,
	maxHeight: 150,
	[theme.breakpoints.down('sm')]: {
		display: 'none',
	},
	[`@media (max-height: ${midScreenHeight}px)`]: {
		display: 'none',
	},
}));
