import { ParsedQs } from 'qs';

import { fetchWrapper, RequestMethod } from 'helpers';
import { QUICKPAY_BASE_ENDPOINT } from 'helpers/getApiUrl';

import { INPUTS_TYPES, SUMMARY_MAPPINGS } from '../constants';
import { ConfigurationWithPaymentData, Configuration, PaymentSystemBaseConfiguration } from '../types';

const APP_VERSION_TOKEN = 'App-V';

const createRequestUrl = (params: ParsedQs): string => {
	const newUrl = params.entity
		? `${QUICKPAY_BASE_ENDPOINT}?clientId=${params.clientId}&entity=${params.entity}`
		: `${QUICKPAY_BASE_ENDPOINT}?clientId=${params.clientId}`;

	return newUrl;
};

const mapQueryParamsToConfigInputs = (stringValue: string | number, config: Configuration, mappedSummary: string) => {
	const newInputs = config.inputs?.map((inputConfig) => {
		if (inputConfig.summaryMapping === mappedSummary) {
			if (
				inputConfig.type !== INPUTS_TYPES.DROPDOWN ||
				(inputConfig.type === INPUTS_TYPES.DROPDOWN &&
					inputConfig.selectionValues?.find((selectionValue) => selectionValue.key === stringValue))
			) {
				inputConfig.value = stringValue;
			}
		}
		return inputConfig;
	});
	return newInputs;
};

const mapDisabledQueryParamsToConfigInputs = (
	stringValue: string | number,
	inputIsDisabled: boolean,
	config: Configuration,
	mappedSummary: string,
) => {
	const newInputs = config.inputs?.map((inputConfig) => {
		if (inputConfig.summaryMapping === mappedSummary) {
			if (
				inputConfig.type !== INPUTS_TYPES.DROPDOWN ||
				(inputConfig.type === INPUTS_TYPES.DROPDOWN &&
					inputConfig.selectionValues?.find((selectionValue) => selectionValue.key === stringValue))
			) {
				inputConfig.isDisabled = inputIsDisabled;
			}
		}
		return inputConfig;
	});
	return newInputs;
};

const returnHasLineQuantityAndDefaultFee = (revenueCode: string, config: Configuration) => {
	const quantityAndFee = {
		hasLineQuantity: false,
		defaultFee: 0,
	};

	const revenueCodeInput = config.inputs?.find((input) => input.summaryMapping === SUMMARY_MAPPINGS.REVENUE_CODE);

	if (revenueCodeInput && revenueCodeInput.type === INPUTS_TYPES.PAYMENT_GROUP) {
		config.paymentGroups?.forEach((group) => {
			const revenueCodeInput = group.selectionValues?.find(
				(selectionValue) => selectionValue.key === revenueCode && selectionValue.hasLineQuantity,
			);
			if (revenueCodeInput) {
				quantityAndFee.hasLineQuantity = true;
				quantityAndFee.defaultFee = revenueCodeInput.defaultFee || 0;
			}
		});
	} else {
		if (revenueCodeInput) {
			revenueCodeInput.selectionValues?.forEach((selection) => {
				if (selection.key === revenueCode && selection.hasLineQuantity) {
					quantityAndFee.hasLineQuantity = true;
					quantityAndFee.defaultFee = selection.defaultFee || 0;
				}
			});
		}
	}
	return quantityAndFee;
};

const returnNoQuantityDefaultFee = (revenueCode: string, config: Configuration) => {
	let noQuantityDefaultFee = 0;

	const revenueCodeInput = config.inputs?.find((input) => input.summaryMapping === SUMMARY_MAPPINGS.REVENUE_CODE);

	if (revenueCodeInput && revenueCodeInput.type === INPUTS_TYPES.PAYMENT_GROUP) {
		config.paymentGroups?.forEach((group) => {
			const revenueCodeInput = group.selectionValues?.find((selectionValue) => selectionValue.key === revenueCode);
			if (revenueCodeInput && !revenueCodeInput.hasLineQuantity && revenueCodeInput.defaultFee) {
				noQuantityDefaultFee = revenueCodeInput.defaultFee;
			}
		});
	} else {
		if (revenueCodeInput) {
			revenueCodeInput.selectionValues?.forEach((selection) => {
				if (selection.key === revenueCode && !selection.hasLineQuantity && selection.defaultFee) {
					noQuantityDefaultFee = selection.defaultFee;
				}
			});
		}
	}
	return noQuantityDefaultFee;
};

const mapLineQuantityValuesToInputs = (revenueCode: string, config: Configuration) => {
	let lineValue = 0;

	const revenueCodeInput = config.inputs?.find((input) => input.summaryMapping === SUMMARY_MAPPINGS.REVENUE_CODE);

	if (revenueCodeInput && revenueCodeInput.type === INPUTS_TYPES.PAYMENT_GROUP) {
		config.paymentGroups?.forEach((group) => {
			const revenueCodeSelection = group.selectionValues?.find(
				(selectionValue) => selectionValue.key === revenueCode && selectionValue.hasLineQuantity,
			);
			if (revenueCodeSelection) {
				lineValue = revenueCodeSelection.defaultFee || 0;
			}
		});
	} else {
		if (revenueCodeInput) {
			revenueCodeInput.selectionValues?.forEach((selection) => {
				if (selection.key === revenueCode && selection.hasLineQuantity) {
					lineValue = selection.defaultFee || 0;
				}
			});
		}
	}

	const quantityInput = config.inputs?.find((input) => input.summaryMapping === SUMMARY_MAPPINGS.QUANTITY);

	const newInputs = config.inputs?.map((input) => {
		if (input.summaryMapping === SUMMARY_MAPPINGS.PRICE) {
			input.value = lineValue;
		}
		if (input.type === INPUTS_TYPES.CURRENCY && quantityInput && quantityInput.value) {
			input.value = Number(quantityInput.value) * lineValue;
		}
		return input;
	});

	return newInputs;
};

const getRevenueCodePrice = (revenueCode: string, config: Configuration) => {
	let revenueCodePrice = 0;

	const revenueCodeInput = config.inputs?.find((input) => input.summaryMapping === SUMMARY_MAPPINGS.REVENUE_CODE);

	if (revenueCodeInput && revenueCodeInput.type === INPUTS_TYPES.PAYMENT_GROUP) {
		config.paymentGroups?.forEach((group) => {
			const revenueCodeSelection = group.selectionValues?.find(
				(selectionValue) => selectionValue.key === revenueCode && selectionValue.hasLineQuantity,
			);
			if (revenueCodeSelection && revenueCodeSelection.defaultFee) {
				revenueCodePrice = revenueCodeSelection.defaultFee;
			}
		});
	} else {
		if (revenueCodeInput) {
			revenueCodeInput.selectionValues?.forEach((selection) => {
				if (selection.key === revenueCode && selection.hasLineQuantity && selection.defaultFee) {
					revenueCodePrice = selection.defaultFee;
				}
			});
		}
	}
	return revenueCodePrice;
};

const selectedRevenueCodeHasQuantity = (revenueCode: string, config: Configuration): boolean => {
	let revenueCodeHasQuantity = false;

	const revenueCodeInput = config.inputs?.find((input) => input.summaryMapping === SUMMARY_MAPPINGS.REVENUE_CODE);

	if (revenueCodeInput && revenueCodeInput.type === INPUTS_TYPES.PAYMENT_GROUP) {
		config.paymentGroups?.forEach((group) => {
			const revenueCodeSelection = group.selectionValues?.find(
				(selectionValue) => selectionValue.key === revenueCode && selectionValue.hasLineQuantity,
			);
			if (revenueCodeSelection) {
				revenueCodeHasQuantity = true;
			}
		});
	} else {
		if (revenueCodeInput) {
			revenueCodeInput.selectionValues?.forEach((selection) => {
				if (selection.key === revenueCode && selection.hasLineQuantity) {
					revenueCodeHasQuantity = true;
				}
			});
		}
	}
	return revenueCodeHasQuantity;
};

const returnConfigData = (
	configData: Configuration<PaymentSystemBaseConfiguration>,
	params: ParsedQs,
): Configuration<PaymentSystemBaseConfiguration> => {
	const { revenueCode, amount, backUrl, referenceNumber, additionalInfo, quantity, fixedParameters } = params;
	const quantityAsNumber = Number(quantity);

	const referrer = document.referrer;
	const revenueCodeHasQuantity = selectedRevenueCodeHasQuantity(String(revenueCode), configData);
	const { hasLineQuantity } = returnHasLineQuantityAndDefaultFee(String(revenueCode), configData);
	const noQuantityDefaultFee = returnNoQuantityDefaultFee(String(revenueCode), configData);

	let returnedConfig;

	if (revenueCode) {
		const newInputs = mapQueryParamsToConfigInputs(String(revenueCode), configData, SUMMARY_MAPPINGS.REVENUE_CODE);
		returnedConfig = {
			...configData,
			inputs: newInputs,
		};
	}
	if (revenueCode && fixedParameters === 'true') {
		const newInputs = mapDisabledQueryParamsToConfigInputs(
			String(revenueCode),
			Boolean(fixedParameters) || false,
			configData,
			SUMMARY_MAPPINGS.REVENUE_CODE,
		);
		returnedConfig = {
			...configData,
			inputs: newInputs,
		};
	}
	// this is to display by default price/quantity inputs and the values inside them
	if (hasLineQuantity) {
		const newInputs = mapLineQuantityValuesToInputs(String(revenueCode), configData);
		returnedConfig = {
			...configData,
			inputs: newInputs,
		};
	}
	if (noQuantityDefaultFee) {
		const newInputs = mapQueryParamsToConfigInputs(String(noQuantityDefaultFee), configData, SUMMARY_MAPPINGS.AMOUNT);
		returnedConfig = {
			...configData,
			inputs: newInputs,
		};
	}
	if (amount) {
		const newInputs = mapQueryParamsToConfigInputs(String(amount), configData, SUMMARY_MAPPINGS.AMOUNT);
		returnedConfig = {
			...configData,
			inputs: newInputs,
		};
	}
	if (amount && fixedParameters === 'true') {
		const newInputs = mapDisabledQueryParamsToConfigInputs(
			String(amount),
			Boolean(fixedParameters) || false,
			configData,
			SUMMARY_MAPPINGS.AMOUNT,
		);
		returnedConfig = {
			...configData,
			inputs: newInputs,
		};
	}
	if (referenceNumber) {
		const newInputs = mapQueryParamsToConfigInputs(
			String(referenceNumber),
			configData,
			SUMMARY_MAPPINGS.REFERENCE_NUMBER,
		);
		returnedConfig = {
			...configData,
			inputs: newInputs,
		};
	}
	if (referenceNumber && fixedParameters === 'true') {
		const newInputs = mapDisabledQueryParamsToConfigInputs(
			String(referenceNumber),
			Boolean(fixedParameters) || false,
			configData,
			SUMMARY_MAPPINGS.REFERENCE_NUMBER,
		);
		returnedConfig = {
			...configData,
			inputs: newInputs,
		};
	}
	if (quantity && revenueCodeHasQuantity) {
		const price = getRevenueCodePrice(String(revenueCode), configData);
		const newAmount = quantityAsNumber * price;

		const newInputs = mapQueryParamsToConfigInputs(quantityAsNumber, configData, SUMMARY_MAPPINGS.QUANTITY);
		returnedConfig = {
			...configData,
			inputs: newInputs,
		};

		if (newAmount && !amount) {
			const newInputs = mapQueryParamsToConfigInputs(newAmount, returnedConfig, SUMMARY_MAPPINGS.AMOUNT);
			returnedConfig = {
				...configData,
				inputs: newInputs,
			};
		}
	}
	if (quantity && fixedParameters === 'true') {
		const newInputs = mapDisabledQueryParamsToConfigInputs(
			String(quantity),
			Boolean(fixedParameters) || false,
			configData,
			SUMMARY_MAPPINGS.QUANTITY,
		);
		returnedConfig = {
			...configData,
			inputs: newInputs,
		};
	}
	if (additionalInfo) {
		const newInputs = mapQueryParamsToConfigInputs(
			String(additionalInfo),
			configData,
			SUMMARY_MAPPINGS.ADDITIONAL_INFORMATION,
		);
		returnedConfig = {
			...configData,
			inputs: newInputs,
		};
	}
	if (additionalInfo && fixedParameters === 'true') {
		const newInputs = mapDisabledQueryParamsToConfigInputs(
			String(additionalInfo),
			Boolean(fixedParameters) || false,
			configData,
			SUMMARY_MAPPINGS.ADDITIONAL_INFORMATION,
		);
		returnedConfig = {
			...configData,
			inputs: newInputs,
		};
	}
	if (backUrl || referrer) {
		returnedConfig = {
			...configData,
			backUrl: backUrl || referrer,
		};
	} else {
		returnedConfig = configData;
	}

	return returnedConfig as Configuration<PaymentSystemBaseConfiguration>;
};

export const fetchQuickPayConfig = (params: ParsedQs) => async (): Promise<ConfigurationWithPaymentData> => {
	const response = await fetchWrapper(createRequestUrl(params), { method: RequestMethod.GET, returnRawResponse: true });
	const configData = await response.json();

	// this is to display by default price/quantity inputs and the values inside them
	const { revenueCode } = params;

	let defaultFee;
	let hasLineQuantity = false;
	if (revenueCode) {
		hasLineQuantity = returnHasLineQuantityAndDefaultFee(String(revenueCode), configData).hasLineQuantity;
		defaultFee = returnHasLineQuantityAndDefaultFee(String(revenueCode), configData).defaultFee;
	}

	const returnedConfig = returnConfigData(configData, params);

	return {
		config: returnedConfig,
		hasLineQuantity: hasLineQuantity,
		data: {
			identifier: '',
			parameters: {
				extendedUrl: '',
				clientID: '',
			},
		},
		appVersion: response.headers.get(APP_VERSION_TOKEN) || 'unknown',
		isQuickPayPage: true,
		isDraft: false,
		...(hasLineQuantity && { defaultFee }),
		savedPayments: [],
		savePayment: () => {
			return {};
		},
		removePayment: () => {
			return {};
		},
		updateIdentifier: () => {
			return {};
		},
	};
};
