import React from 'react';

import { usePaymentParameters } from 'components/features/Configuration/hooks';

import { MultiPaymentsTable } from './MultiPaymentsTable';
import { SinglePaymentTable } from './SinglePaymentTable';

export const SummaryTable = () => {
	const paymentParameters = usePaymentParameters();

	if (paymentParameters.payments?.length === 1) {
		return <SinglePaymentTable />;
	}
	return <MultiPaymentsTable />;
};
