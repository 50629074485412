import { styled } from '@mui/material/styles';

const midScreenHeight = 706;

export const StyledCaptchaContainer = styled('div')(({ theme }) => ({
	padding: theme.spacing(4, 2),
	flexGrow: 2,
	[theme.breakpoints.up('xs')]: {
		padding: theme.spacing(4, 2),
		flexGrow: 0,
	},
	[theme.breakpoints.up('sm')]: {
		padding: theme.spacing(6),
		paddingTop: theme.spacing(4),
		flexGrow: 0,
	},
	[`@media (max-height: ${midScreenHeight}px)`]: {
		marginBottom: theme.spacing(2, 1),
	},
	display: 'flex',
	justifyContent: 'center',
	overflowY: 'auto',
}));
