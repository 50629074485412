import { ReactNode } from 'react';

import { InitialQueryPayment } from 'components/features/Configuration/types';

export const DEFAULT_PARAMS_LABEL_MAPPING: { [key in keyof Partial<InitialQueryPayment>]: string } = {
	referenceNumberDescription: 'Description',
	referenceNumber: 'Reference Number',
	amount: 'Amount',
};

export type SummaryItem = {
	name: string;
	value: ReactNode;
};
