import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledErrorMessage = styled(Typography)(({ theme }) => ({
	fontSize: '1em',
	color: '#ae0000',
	paddingLeft: theme.spacing(1),
	visibility: 'hidden',
	minHeight: '22.5px',

	'&.visible': {
		visibility: 'visible',
	},
}));
