import { TableContainer, TableCell, TableBody, Table as MUITable, TableHead, TableFooter } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { TableRow } from './TableRow';
import { TablePropTypes } from './types';

export const Table = <GenericData,>({
	data,
	columns,
	modifier,
	shouldHideTableHeader,
	footer,
	caption,
	stickyHeader = true,
}: PropsWithChildren<TablePropTypes<GenericData>>) => {
	const formattedData = modifier ? data.map(modifier) : data;
	return (
		<>
			<TableContainer>
				<MUITable sx={{ minWidth: 300, borderCollapse: 'collapse' }} stickyHeader={stickyHeader} tabIndex={0}>
					{/* the row bellow is used only for accesability */}
					{caption && <caption style={{ visibility: 'hidden' }}>{caption}</caption>}
					{!shouldHideTableHeader && (
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell align={column.align} key={`column-${String(column.id)}`} {...column.additionalCellProps}>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
					)}
					<TableBody>
						{formattedData.map((row, index) => {
							return (
								<TableRow key={index}>
									{columns.map((column, cellIndex) => {
										return (
											<TableCell key={cellIndex} {...column.additionalCellProps} align={column.align || 'left'}>
												{row[column.id] as React.ReactNode}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
					{footer && (
						<TableFooter>
							<TableRow>
								{columns.map((column) => (
									<TableCell align={column.align} key={`footer-${String(column.id)}`} {...column.additionalCellProps}>
										<strong>{footer[column.id]}</strong>
									</TableCell>
								))}
							</TableRow>
						</TableFooter>
					)}
				</MUITable>
			</TableContainer>
		</>
	);
};
