import { styled } from '@mui/material/styles';

export const TimerContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	borderBottom: `1px solid ${theme.palette.divider}`,
	'&.danger': {
		color: theme.palette.error.main,
	},
}));
