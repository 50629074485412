export interface CustomErrorPropTypes {
	messages: string[];
	title?: string;
	onReturn: () => void;
	hideReturnButton?: boolean;
}

export enum ErrorType {
	XPAY_HASH_INTEGRITY_FAILED = 10001,
	XPAY_INVALID_VALUE = 10002,
	MISSING_CONFIGURATION = 10003,
	UNIQUE_ID = 10004,
}
