import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { StyledAdditionalMessage } from './StyledAdditionalMessage';
import { StyledContainer } from './StyledContainer';
import { StyledImage } from './StyledImage';
import { StyledReturnButton } from './StyledReturnButton';
import { StyledSubtitle } from './StyledSubtitle';
import errorImage from '../../../theme/assets/images/error.svg';
import { usePaymentParameters, useGetIsQuickPayPage, useGetIsPayByLink } from '../Configuration/hooks';
import { PaymentLayout } from '../Payment/PaymentLayout';

export const PaymentError: React.FunctionComponent<{ declined?: boolean; sessionExpired?: boolean }> = ({
	declined = false,
	sessionExpired = false,
}) => {
	const navigate = useNavigate();
	const { returnUrl, extendedUrl } = usePaymentParameters();
	const isPayByLink = useGetIsPayByLink();
	const intl = useIntl();
	const isQuickPayPage = useGetIsQuickPayPage();

	let title = intl.formatMessage({ id: 'generic-error__title' });
	let message = intl.formatMessage({ id: 'payment-error__message' });

	if (sessionExpired) {
		title = intl.formatMessage({ id: 'payment-session-expired__title' });
		message = intl.formatMessage({ id: 'payment-session-expired__message' });
	} else if (declined) {
		title = intl.formatMessage({ id: 'payment-cancellation__title' });
		message = intl.formatMessage({ id: 'payment-cancellation__message' });
	}

	const buttonText = intl.formatMessage({ id: 'button__home' });

	const onReturn = () => {
		if (isQuickPayPage) {
			window.location.replace('/payments');
			return;
		}

		if (returnUrl) {
			window.location.replace(returnUrl);
			return;
		}

		if (extendedUrl) {
			window.location.replace(extendedUrl);
			return;
		}

		navigate(-1);
	};

	return (
		<PaymentLayout>
			<StyledContainer>
				<StyledImage src={errorImage} alt="Man holding an error sign" />
				<StyledSubtitle color="primary">{title}</StyledSubtitle>
				<Divider flexItem sx={{ minHeight: 1 }} />
				<StyledAdditionalMessage variant="body2" color="primary" align="center">
					{message}
				</StyledAdditionalMessage>
				{!isPayByLink && (
					<StyledReturnButton
						variant="contained"
						color="secondary"
						startIcon={<Icon>home</Icon>}
						onClick={onReturn}
						size="large"
						aria-label={buttonText}
					>
						{buttonText}
					</StyledReturnButton>
				)}
			</StyledContainer>
		</PaymentLayout>
	);
};
