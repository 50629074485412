import { TableRow as MUITableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableRow = styled(MUITableRow)(({ theme }) => ({
	'& th': {
		backgroundColor: 'transparent',
	},
	'& td, & th': {
		[theme.breakpoints.down('xs')]: {
			fontSize: 11,
		},
	},
	'& td:first-child, & th:first-child': {
		paddingLeft: theme.spacing(6),
	},
	'& td:last-child, & th:last-child': {
		paddingRight: theme.spacing(6),
	},
	[theme.breakpoints.down('sm')]: {
		'& td:first-child, & th:first-child': {
			paddingLeft: theme.spacing(2),
		},
		'& td:last-child, & th:last-child': {
			paddingRight: theme.spacing(2),
		},
	},
}));
