import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { StyledAdditionalMessage } from './StyledAdditionalMessage';
import { StyledContainer } from './StyledContainer';
import { StyledImage } from './StyledImage';
import { StyledReturnButton } from './StyledReturnButton';
import { StyledSubtitle } from './StyledSubtitle';
import confirmationImage from '../../../theme/assets/images/payment_confirmation.svg';
import { usePaymentParameters, useGetIsQuickPayPage, useGetIsPayByLink } from '../Configuration/hooks';
import { PaymentLayout } from '../Payment/PaymentLayout';

export const PaymentProcessing: React.FunctionComponent = () => {
	const navigate = useNavigate();
	const { returnUrl, extendedUrl } = usePaymentParameters();
	const isPayByLink = useGetIsPayByLink();
	const intl = useIntl();
	const isQuickPayPage = useGetIsQuickPayPage();

	const processingMessage = intl.formatMessage({ id: 'payment-processing__notify-message' });
	const title = intl.formatMessage({ id: 'payment-processing__title' });
	const buttonText = intl.formatMessage({ id: 'button__home' });

	const onReturn = () => {
		if (isQuickPayPage) {
			window.location.replace('/payments');
			return;
		}

		if (returnUrl) {
			window.location.replace(returnUrl);
			return;
		}

		if (extendedUrl) {
			window.location.replace(extendedUrl);
			return;
		}

		navigate(-1);
	};

	return (
		<PaymentLayout>
			<StyledContainer>
				<StyledImage src={confirmationImage} alt="Payment confirmation" />
				<StyledSubtitle color="primary">{title}</StyledSubtitle>
				<Divider flexItem sx={{ minHeight: 1 }} />
				<StyledAdditionalMessage variant="body2" color="primary" align="center">
					{processingMessage}
				</StyledAdditionalMessage>
				{!isPayByLink && (
					<StyledReturnButton
						variant="contained"
						color="secondary"
						startIcon={<Icon>home</Icon>}
						onClick={onReturn}
						size="large"
						aria-label={buttonText}
					>
						{buttonText}
					</StyledReturnButton>
				)}
			</StyledContainer>
		</PaymentLayout>
	);
};
