import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ErrorTitle = styled(Typography)(({ theme }) => ({
	marginBottom: theme.spacing(2),
	padding: theme.spacing(0, 6),
	color: theme.palette.common.white,
	fontWeight: 700,
	fontSize: 24,
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(0),
		marginBottom: theme.spacing(2),
		fontSize: 20,
	},
}));
