import { styled } from '@mui/material/styles';

const midScreenHeight = 706;

export const StyledAmount = styled('h1')(({ theme }) => ({
	fontSize: '30px',
	lineHeight: '0.9',
	marginBottom: theme.spacing(2),
	fontWeight: 700,
	textAlign: 'center',
	color: theme.palette.text.primary,
	[theme.breakpoints.down('sm')]: {
		marginBottom: theme.spacing(1),
	},
	[`@media (max-height: ${midScreenHeight}px)`]: {
		marginBottom: theme.spacing(1),
	},
	'&:before, &:after': {
		content: 'attr(data-prefix)',
		display: 'inline-block',
		paddingRight: '8px',
		color: theme.palette.text.primary,
	},
	'&:after': {
		content: 'attr(data-suffix)',
		paddingRight: 0,
		paddingLeft: '8px',
	},
	'&.inverted': {
		color: theme.palette.primary.dark,
		'&:after,	&:before': {
			color: theme.palette.primary.dark,
		},
	},
}));
