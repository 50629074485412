import { fetchWrapper, RequestMethod } from 'helpers';

import { PaymentSession } from '../types/paymentSystem';

export const addChargeFees = async (paymentId: string, body: PaymentSession): Promise<number> =>
	fetchWrapper<number>(`/payments/${paymentId}/fees`, {
		method: RequestMethod.PATCH,
		body,
	});

export const revertChargeFees = async (paymentId: string, body: PaymentSession): Promise<number> =>
	fetchWrapper<number>(`/payments/${paymentId}/revertfees`, {
		method: RequestMethod.PATCH,
		body,
	});
