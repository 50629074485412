import { common, grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

export const StyledStripeInput = styled('input')(({ theme }) => ({
	border: '1px solid rgba(33, 48, 66, 0.1)',
	boxShadow: 'rgba(50, 50, 93, 0.14902) 1px 1px 4px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px',
	borderRadius: '8px',
	margin: '3px 10px',
	transition: 'box-shadow 0.3s, border 0.3s',
	backgroundColor: common.white,
	'&:-webkit-autofill': {
		background: common.white,
	},
	padding: '8px 8px 8px 30px',
	fontSize: '18px',
	outline: 'none',
	width: '100%',
	color: common.black,
	'&::placeholder': {
		fontFamily: 'inherit',
		color: grey,
		opacity: 1,
	},
	'&:focus': {
		boxShadow: 'rgba(50, 50, 93, 0.14902) 2px 1px 9px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px',
	},
	'&.stripeInputError': {
		borderColor: theme.palette.error.main,
	},
}));
