import { fetchWrapper, RequestMethod } from 'helpers';

export const getReceipt = (paymentId: string): Promise<string> =>
	fetchWrapper<string>(`/payments/${paymentId}/receipt`, { method: RequestMethod.GET });

export const printDocument = (request: Promise<string>, toggleMessage: (state: boolean) => void): Promise<void> =>
	new Promise<void>((resolve, reject) => {
		toggleMessage(true);
		const printWindow = window.open();

		if (printWindow)
			request.then((content) => {
				printWindow.document.write(content);
				printWindow.document.close();
				printWindow.focus();
				printWindow.print();
				resolve();
				toggleMessage(false);
			});
		else reject();
	});
