import { TableContainer, Table as MUITable, TableBody, TableRow, TableCell } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { SinglePaymentTablePropTypes } from './types';

export const SinglePaymentItemTable = ({ data }: PropsWithChildren<SinglePaymentTablePropTypes>) => {
	return (
		<>
			<TableContainer>
				<MUITable sx={{ minWidth: 300, borderCollapse: 'collapse' }} stickyHeader tabIndex={0}>
					<TableBody>
						{data.map((element) => (
							<TableRow key={element.name}>
								<TableCell variant="head" sx={{ backgroundColor: 'transparent', fontWeight: 'normal' }}>
									{element.name}
								</TableCell>
								<TableCell align="right">{element.value || ''}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</MUITable>
			</TableContainer>
		</>
	);
};
