import { PaletteColor, styled } from '@mui/material/styles';

export const LoadingContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	flex: 1,
	height: '100%',
	backgroundColor: theme.palette.custom
		? theme.palette.custom.main
		: theme.palette.primary[700 as unknown as keyof PaletteColor],
}));
