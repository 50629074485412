import React, { FunctionComponent } from 'react';

import { Cube, SecondCube } from './Cube';
import { LoadingContainer } from './LoadingContainer';
import { Spinner } from './Spinner';

export const Loading: FunctionComponent = () => {
	return (
		<LoadingContainer>
			<Spinner>
				<Cube></Cube>
				<SecondCube></SecondCube>
			</Spinner>
		</LoadingContainer>
	);
};
