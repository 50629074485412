import { ParsedQs } from 'qs';
import uniqid from 'uniqid';

import { SessionStorage } from 'helpers';

import { INITIAL_PARAMETERS_EXPIRE_TIME, INITIAL_PARAMETERS_SESSION_KEY } from './constants';
import {
	Configuration,
	ExternalURLParamsMappings,
	ExternalURLPaymentMappings,
	InitialQueryParameters,
	InitialQueryPayment,
	RecurrentPrice,
} from './types';
import { roundToPrecision } from '../Payment/helpers';
import { PaymentFields } from '../QuickPay/constants';

const getPaymentsFromQueryParams = (
	queryParams: Record<string, string | number | undefined>,
): InitialQueryPayment[] => {
	const payments: InitialQueryPayment[] = [];
	let paymentIndex = 0;
	while (paymentIndex >= 0) {
		const paymentMappingsEntries = Object.entries(ExternalURLPaymentMappings) as [
			keyof typeof ExternalURLPaymentMappings,
			ExternalURLPaymentMappings,
		][];
		const suffix = paymentIndex ? `_${paymentIndex}` : '';
		const payment = paymentMappingsEntries.reduce((previous, [key, mappedKey]) => {
			const formattedKey = `${key}${suffix}`;
			const value = queryParams[formattedKey];
			if (value && typeof value === 'string') {
				return { ...previous, [mappedKey]: value.trim() };
			} else if (value) {
				return { ...previous, [mappedKey]: value };
			}
			return previous;
		}, {} as InitialQueryPayment);
		if (!payment.revenueCode) {
			break;
		}
		payments.push(payment);
		paymentIndex++;
	}
	return payments;
};

export const mapPaymentParameters = (queryParams: URLSearchParams): InitialQueryParameters => {
	const lowerCaseQueryParams = Array.from(queryParams.keys()).reduce(
		(previous, current) => {
			let value: string | number | null = queryParams.get(current);

			if (value && typeof value === 'string') {
				value = value.trim();
			}

			const currentParam = current.toLowerCase();
			if (currentParam === 'am' || currentParam.includes('am_')) {
				value = roundToPrecision(Number(value), 2);
			}

			return { ...previous, [currentParam]: value };
		},
		{} as Record<keyof typeof ExternalURLParamsMappings, string | number | undefined>,
	);

	const queryMappingsEntries = Object.entries(ExternalURLParamsMappings) as [
		keyof typeof ExternalURLParamsMappings,
		ExternalURLParamsMappings,
	][];
	const mappedQueryParameters = queryMappingsEntries.reduce((previous, [key, mappedKey]) => {
		const value = lowerCaseQueryParams[key];
		if (value) {
			return { ...previous, [mappedKey]: value };
		}
		return previous;
	}, {} as InitialQueryParameters);
	const payments = getPaymentsFromQueryParams(lowerCaseQueryParams);
	return { ...mappedQueryParameters, payments };
};

export const createInitialParametersSession = (
	data: Record<string, string | number | undefined | boolean | Array<unknown>>,
) => {
	const timestamp = new Date(Date.now()).getTime();
	SessionStorage.set(INITIAL_PARAMETERS_SESSION_KEY, { timestamp, data });
};

export const getInitialParametersFromSession = <T = unknown>(): T | void => {
	const rawData = SessionStorage.get<{ timestamp: number; data: T }>(INITIAL_PARAMETERS_SESSION_KEY);
	const timestamp = new Date(Date.now()).getTime();
	if (!rawData || !rawData.data || timestamp - rawData.timestamp > INITIAL_PARAMETERS_EXPIRE_TIME) {
		return;
	}
	return rawData.data;
};

export const removeInitialParametersFromSession = (): void => {
	SessionStorage.remove(INITIAL_PARAMETERS_SESSION_KEY);
};

export const getQueryParams = (): ParsedQs => {
	const params = new URLSearchParams(window.location.search);
	return {
		entity: params.get('entity') || '',
		clientId: params.get('client') || '',
		revenueCode: params.get('pmtTyp') || '',
		referenceNumber: params.get('refNumber') || '',
		amount: params.get('am') || '',
		additionalInfo: params.get('additionalInfo') || '',
		quantity: params.get('qty') || '',
		backUrl: params.get('burl') || '',
		fixedParameters: params.get('areParamsFixed') || '',
		sessionId: params.get('sid') || '',
		paymentIntentClientSecret: params.get('payment_intent_client_secret') || '',
	};
};

export const urlHasNoParams = (): boolean => {
	const params = new URLSearchParams(window.location.search);
	return params.size === 0;
};

export const getPropertyFromCurrentConfig = (quickPayConfig: Configuration, property: string): string => {
	const input = quickPayConfig.inputs?.find((configInput) => configInput.summaryMapping === property);
	if (input?.value) {
		return String(input?.value);
	}
	return 'undefined';
};

export const getNumberPropertyFromCurrentConfig = (quickPayConfig: Configuration, property: string): number => {
	const input = quickPayConfig.inputs?.find((configInput) => configInput.summaryMapping === property);

	if (!input?.value || Number.isNaN(Number(input.value))) {
		return 0;
	}

	return Number(input.value);
};

export const getReferenceNumberDescription = (quickPayConfig: Configuration): string => {
	const revenueCode = getPropertyFromCurrentConfig(quickPayConfig, PaymentFields.revenueCode);
	const revenueCodeInputSelection = quickPayConfig.inputs?.find(
		(configInput) => configInput.summaryMapping === PaymentFields.revenueCode,
	);
	const referenceNumberDescription = revenueCodeInputSelection?.selectionValues?.find(
		(selectionValue) => selectionValue.key === revenueCode,
	);
	return String(referenceNumberDescription?.value);
};

export const mapQuickPayParameters = (quickPayConfig: Configuration, savedPayments: InitialQueryPayment[]) => {
	return {
		area: quickPayConfig.paymentSystem.areas || quickPayConfig.area,
		till: quickPayConfig.paymentSystem.tills || quickPayConfig.till,
		clientID: quickPayConfig.clientID,
		entity: quickPayConfig.entity,
		isQuickPay: true,
		payments: savedPayments,
		returnUrl: '/',
	};
};

const returnAllPaymentInputs = (quickPayConfig: Configuration) => {
	const allPaymentInputsValues = quickPayConfig.inputs?.map((input) => {
		return {
			summaryMapping: String(input.summaryMapping),
			value: getPropertyFromCurrentConfig(quickPayConfig, String(input.summaryMapping)),
		};
	});
	return allPaymentInputsValues;
};

const getRecurrenceDataFromQuickpayConfig = (
	quickPayConfig: Configuration,
	property: string,
	recurrentPrices?: RecurrentPrice[],
) => {
	const revenueCode = quickPayConfig.inputs?.find((configInput) => configInput.summaryMapping === property);

	if (recurrentPrices?.find((price) => price.revenueCode === revenueCode?.value)) {
		return revenueCode?.recurrenceData;
	}
	return undefined;
};

export const returnPaymentFromConfig = (quickPayConfig: Configuration, recurrentPrices?: RecurrentPrice[]) => {
	return {
		amount: getNumberPropertyFromCurrentConfig(quickPayConfig, PaymentFields.amount),
		referenceNumber: getPropertyFromCurrentConfig(quickPayConfig, PaymentFields.referenceNumber),
		referenceNumberDescription: getReferenceNumberDescription(quickPayConfig),
		revenueCode: getPropertyFromCurrentConfig(quickPayConfig, PaymentFields.revenueCode),
		...(getPropertyFromCurrentConfig(quickPayConfig, PaymentFields.additionalInformation) !== 'undefined' && {
			additionalInformation: getPropertyFromCurrentConfig(quickPayConfig, PaymentFields.additionalInformation),
		}),
		...(getPropertyFromCurrentConfig(quickPayConfig, PaymentFields.additionalInformationDescription) !==
			'undefined' && {
			additionalInformationDescription: getPropertyFromCurrentConfig(
				quickPayConfig,
				PaymentFields.additionalInformationDescription,
			),
		}),
		...(getPropertyFromCurrentConfig(quickPayConfig, PaymentFields.quantity) !== 'undefined' &&
			getPropertyFromCurrentConfig(quickPayConfig, PaymentFields.quantity) !== '' && {
				quantity: getNumberPropertyFromCurrentConfig(quickPayConfig, PaymentFields.quantity),
			}),
		paymentInputs: returnAllPaymentInputs(quickPayConfig),
		recurrenceData: getRecurrenceDataFromQuickpayConfig(quickPayConfig, PaymentFields.revenueCode, recurrentPrices),
		id: uniqid(),
	};
};

export const emptyConfigInputs = (quickPayConfig: Configuration) => {
	const newInputs = quickPayConfig?.inputs?.map((input) => {
		input.value = '';
	});
	return {
		...quickPayConfig,
		inputs: newInputs,
	};
};
