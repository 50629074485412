import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { readConfig, loadGoogleTagManager } from 'helpers';

import App from './App';
import { LangContextProvider } from './state/LangContext';

import './index.css';

readConfig();
if (window.ENV.ANALYTICS_ENABLED) {
	loadGoogleTagManager();
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<Router>
		<LangContextProvider>
			<App />
		</LangContextProvider>
	</Router>,
);
