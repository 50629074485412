import React, { FunctionComponent } from 'react';

import { formatNumber } from 'components/features/Payment/helpers';
import { useConfig } from 'components/features/QuickPay/hooks';

import { AmountText } from './AmountText';

export interface AmountPropTypes {
	balance: number;
}

export const Amount: FunctionComponent<AmountPropTypes> = ({ balance }) => {
	const {
		amountFormat,
		paymentSystem: { currency, currencySymbol },
	} = useConfig();

	const numberFormatParts = new Intl.NumberFormat(undefined, { currency, style: 'currency' }).formatToParts();
	const symbol = currencySymbol || numberFormatParts.find((part) => part.type === 'currency')?.value || '';

	return (
		<AmountText variant="caption" color="initial">
			{`${symbol}${
				amountFormat && Object.keys(amountFormat).length > 0
					? formatNumber(balance, amountFormat)
					: balance.toLocaleString(undefined, {
							currency,
							style: 'decimal',
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})
			}`}
		</AmountText>
	);
};
