import { UserAddress } from 'components/features/QuickPay/types/types';

export interface PaymentData {
	amount: number;
	email?: string;
	address?: UserAddress;
}

export interface PaymentState {
	isPaymentModalOpen: boolean;
	paymentData: PaymentData;
	isLoading?: boolean;
	paymentError: Error | null;
	paymentLoading: boolean;
	paymentDeclined: boolean;
	paymentCompleted: boolean;
	paymentSessionExpired?: boolean;
	paymentIdentifier?: string;
}

export interface PaymentForm {
	cardNumber: string;
	expiryDate: string;
	cvv: string;
	cardHolder: string;
}

export interface PaymentSessionConfirmation {
	paymentId: string;
	returnUrlParameters: Record<string, string | number>;
	status: string;
}

export interface PaymentSessionCancellation {
	paymentId: string;
	status: string;
}

export interface PaymentContextType extends PaymentState {
	initiatePayment: (data: PaymentData) => void;
	updatePayment: (data: PaymentData) => void;
	onClose: () => void;
	onCompletePayment: (backUrlParameters: Record<string, string | number>) => void;
	onError: (error: Error | null, returnUrlParams?: Record<string, string | number>) => void;
	onLoading: (isLoading: boolean) => void;
	onCancel: (returnUrlParameters?: Record<string, string | number>, isSessionExpired?: boolean) => void;
}
export enum ReturnUrlParametersMappings {
	responseCode = 'Rc',
	sessionId = 'SID',
	receiptNumber = 'RecNo',
	authorizationCode = 'AuthCode',
	cardType = 'CardTyp',
	paymentAmount = 'Pmt',
	hash = 'hash',
}

export enum AsyncMethodReturnUrlParametersMappings {
	responseCode = 'Rc',
	sessionId = 'SID',
	paymentIntent = 'pid',
	paymentAmount = 'Pmt',
	hash = 'hash',
}
