import * as EmailValidator from 'email-validator';

import { InitialQueryParameters, ExternalURLParamsMappings } from 'components/features/Configuration/types';
import { UserAddress } from 'components/features/QuickPay/types/types';

import { DEFAULT_PARAMS_LABEL_MAPPING } from './constants';

export const mapPaymentParameterLabels = (paramLabelMappings: { [key: string]: string }) => {
	const labelKeys = Object.values(paramLabelMappings);
	const customLabelMappings = labelKeys.reduce((parameters, currentParameterKey): InitialQueryParameters => {
		const lowercaseParameterKey = currentParameterKey.toLowerCase();
		const mappedKey = ExternalURLParamsMappings[lowercaseParameterKey as keyof typeof ExternalURLParamsMappings];
		if (mappedKey) {
			const label = Object.keys(paramLabelMappings)[labelKeys.indexOf(lowercaseParameterKey)];

			return { ...parameters, [mappedKey]: label };
		}
		return parameters;
	}, {} as InitialQueryParameters);

	return { ...DEFAULT_PARAMS_LABEL_MAPPING, ...customLabelMappings };
};

export const validateEmailFormat = (email: string) => {
	return EmailValidator.validate(email);
};

export const validateUserAddress = (userAddress: UserAddress | undefined): boolean => {
	if (!userAddress) {
		return false;
	}
	const { name, address, city, country, postalCode } = userAddress;
	return !!name && !!address && !!city && !!country && !!postalCode;
};
