import { fetchWrapper, RequestMethod } from 'helpers';

import { InitiatePaymentSession, PaymentSession } from '../types/paymentSystem';

export const createPaymentSession =
	(paymentId: string, body: PaymentSession, isDraft: boolean) => async (): Promise<InitiatePaymentSession> =>
		fetchWrapper<InitiatePaymentSession>(`/payments/${paymentId}/session${isDraft ? '/draft' : ''}`, {
			method: RequestMethod.POST,
			body,
		});
