import React, { FunctionComponent } from 'react';

import { Amount, Table, TableColumns } from 'components/common';
import { useClientBranding, usePaymentParameters } from 'components/features/Configuration/hooks';
import { InitialQueryPayment } from 'components/features/Configuration/types';

import { mapPaymentParameterLabels } from '../helpers';

export const MultiPaymentsTable: FunctionComponent = () => {
	const { paymentSummaryLabelMappings } = useClientBranding();
	const customParamsLabelMapping = mapPaymentParameterLabels(paymentSummaryLabelMappings || {});
	const paymentParameters = usePaymentParameters();

	const multiPaymentTableColumns: TableColumns<InitialQueryPayment> = Object.entries(customParamsLabelMapping).map(
		([id, label], index, completeArray) => ({
			id: id as keyof InitialQueryPayment,
			label: label as string,
			align: index === completeArray.length - 1 ? 'right' : 'left',
		}),
	);
	const multiPaymentsTableModifier = (tableRow: InitialQueryPayment) => ({
		...tableRow,
		amount: <Amount balance={tableRow.amount} />,
	});
	return (
		<>
			<Table
				data={paymentParameters.payments || []}
				columns={multiPaymentTableColumns}
				modifier={multiPaymentsTableModifier}
			/>
		</>
	);
};
