import { styled } from '@mui/material/styles';

export const HeaderLink = styled('a')(({ theme }) => ({
	fontWeight: 600,
	fontSize: 12,
	textDecoration: 'none',
	color: theme.palette.getContrastText(theme.palette.secondary.main),
	[theme.breakpoints.down('sm')]: {
		fontSize: 10,
	},
	'&::after': {
		content: "'|'",
		margin: theme.spacing(0, 1),
	},
	'&:last-child::after': {
		content: 'none',
	},
}));
