import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { formatTime, MINUTE } from 'helpers/formatTime';

import { TimerContainer } from './TimerContainer';

interface TimerProps {
	timeLeft?: number;
}

const WARNING_LIMIT = 5 * MINUTE;

export const Timer: FunctionComponent<TimerProps> = ({ timeLeft = 0 }) => {
	const intl = useIntl();

	return (
		<TimerContainer
			className={classNames({ danger: timeLeft <= WARNING_LIMIT })}
			tabIndex={0}
			role="timer"
			aria-atomic="true"
		>
			{intl.formatMessage({ id: 'stripe-payment__session-timer' }, { time: formatTime(timeLeft) })}
		</TimerContainer>
	);
};
