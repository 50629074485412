import { fetchWrapper, RequestMethod } from 'helpers';

import { PaymentSessionCancellation } from '../types/payment';

export const cancelPaymentSession =
	(paymentId: string, isDraft: boolean, isAttemptLimitReached?: boolean) =>
	async (): Promise<PaymentSessionCancellation> =>
		fetchWrapper<PaymentSessionCancellation>(
			`/payments/${paymentId}/cancel${isDraft ? '/draft' : ''}${isAttemptLimitReached ? '?attemptLimit=true' : ''}`,
			{
				method: RequestMethod.POST,
			},
		);
