export const SECOND = 1000;
export const MINUTE = SECOND * 60;

export const formatTime = (time: number): string => {
	const minutes = `${Math.trunc(time / MINUTE)}`.padStart(2, '0');
	time %= MINUTE;
	const seconds = `${Math.trunc(time / SECOND)}`.padStart(2, '0');

	return `${minutes}:${seconds}`;
};
