import { styled } from '@mui/material/styles';

export const PaymentResultContainer = styled('div')(({ theme }) => ({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	overflow: 'auto',
	backgroundColor: theme.palette.background.paper,
	maxWidth: '100%',
}));
