import { styled } from '@mui/material/styles';

export const LinkContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: theme.spacing(4),
	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
	},
}));
