import { useContext, Context } from 'react';

import { ConfigurationContext } from '../Configuration/ConfigurationContext';
import { PaymentSystemBaseConfiguration, ConfigurationWithPaymentData } from '../Configuration/types';

export const useConfiguration = <T extends PaymentSystemBaseConfiguration = PaymentSystemBaseConfiguration>() =>
	useContext<ConfigurationWithPaymentData<T>>(
		ConfigurationContext as unknown as Context<ConfigurationWithPaymentData<T>>,
	);

export const useClientBranding = () => useConfiguration().config.branding;

export const useConfig = () => useConfiguration().config;

export const useSetInputValue = () => useContext<ConfigurationWithPaymentData>(ConfigurationContext).setInputValue;

export const useSetQuickPayConfig = () =>
	useContext<ConfigurationWithPaymentData>(ConfigurationContext).setQuickPayConfig;

export const useSetInputDropdownValues = () =>
	useContext<ConfigurationWithPaymentData>(ConfigurationContext).setInputDropdownValues;

export const useSavePayment = () => useContext<ConfigurationWithPaymentData>(ConfigurationContext).savePayment;

export const useRemovePayment = () => useContext<ConfigurationWithPaymentData>(ConfigurationContext).removePayment;

export const useSavedPayments = () => useConfiguration().savedPayments;

export const useSetQuickPayPage = () => useConfiguration().setQuickPayCurrentPage;

export const useAmountSeparators = () => {
	const config = useConfiguration().config;
	const { amountFormat } = config;

	return {
		thousandsSeparator: amountFormat
			? amountFormat?.hasThousandsSeparator
				? { thousandSeparator: amountFormat?.thousandsSeparator }
				: {}
			: { thousandSeparator: true },

		decimalSeparator: amountFormat
			? { decimalSeparator: amountFormat?.decimalSeparator || '' }
			: { decimalSeparator: '.' },
	};
};

export const useUpdateIdentifier = () => useConfiguration().updateIdentifier;
