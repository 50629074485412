import { OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledInput = styled(OutlinedInput)(({ theme }) => ({
	width: '100%',
	'& input': {
		paddingLeft: theme.spacing(2),
		fontSize: theme.typography.fontSize,
	},
	'& input[type=number]': {
		'-moz-appearance': 'textfield',
	},
	'& input[type=number]::-webkit-outer-spin-button': {
		'-webkit-appearance': 'none',
		margin: 0,
	},
	'& input[type=number]::-webkit-inner-spin-button': {
		'-webkit-appearance': 'none',
		margin: 0,
	},
}));
