export const formatExternalUrl = (url: string, parameters: Record<string, string | number>) => {
	try {
		const fullUrl = new URL(url);
		Object.entries(parameters).forEach(([key, value]) => {
			if (value) {
				fullUrl.searchParams.set(key, `${value}`);
			}
		});
		return fullUrl.toString();
	} catch (_e) {
		return '';
	}
};
