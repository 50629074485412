import { StripeError } from '@stripe/stripe-js';

import { StripeDeclineCodes, StripeErrorCodes } from './constants';
import { ReturnUrlParametersMappings, AsyncMethodReturnUrlParametersMappings } from './types/payment';
import { InitialQueryPayment, AmountFormat } from '../Configuration/types';

export const FALLBACK_CURRENCY = 'gbp';

export const mapReturnUrlParameters = (
	parameters: Record<string, string | number>,
	asyncMethod?: boolean,
): Record<string, string | number> => {
	const mapping = asyncMethod ? AsyncMethodReturnUrlParametersMappings : ReturnUrlParametersMappings;

	return Object.entries(parameters).reduce((mappedParameters, entry) => {
		if (mapping[entry[0] as keyof typeof mapping]) {
			return {
				...mappedParameters,
				[mapping[entry[0] as keyof typeof mapping]]: entry[1],
			};
		}
		return mappedParameters;
	}, {});
};

export const calculatePaymentsTotalAmount = (initialQueryPayments: InitialQueryPayment[] = []) =>
	initialQueryPayments.reduce((amount, payment) => {
		return amount + roundToPrecision(payment.amount);
	}, 0);

export const formatNumber = (number: number, format?: AmountFormat): string => {
	const value = number.toString();

	if (value === '') {
		return '';
	}

	if (!format) {
		return value;
	}

	const preFormatted = number
		.toFixed(2)
		.replace('.', format.hasDecimalSeparator ? format.decimalSeparator || '' : '')
		.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${format.hasThousandsSeparator ? format.thousandsSeparator || '' : ''}`)
		.replace('-', format.isSigned ? '-' : '')
		.padStart(format.length ? format.length - 1 : 0, format.trailingCharacter || '');

	const result = preFormatted.padStart(
		format.trailingCharacter ? format.length || 0 : number < 0 ? preFormatted.length + 1 : preFormatted.length,
		format.trailingCharacter || '',
	);

	if (format.length && result.length > format.length) {
		return '';
	}

	return result;
};

export const getCurrentDate = () =>
	new Date(Date.now()).toLocaleString(undefined, {
		year: 'numeric',
		month: '2-digit',
		day: 'numeric',
	});

export const roundToPrecision = (number: number, precision = 2): number => {
	const precisionValue = Math.pow(10, precision);
	return Math.round((number + Number.EPSILON) * precisionValue) / precisionValue;
};

export const AmountFormatter = (
	currency: string,
	currencySymbol: string | undefined,
	amountFormat: AmountFormat | undefined,
) => {
	const format = (value: number) => {
		if (!amountFormat || Object.keys(amountFormat).length < 1) {
			return currencySymbol
				? `${currencySymbol}${new Intl.NumberFormat(undefined, {
						style: 'decimal',
						currency: currency || FALLBACK_CURRENCY,
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}).format(value)}`
				: new Intl.NumberFormat(undefined, {
						style: 'currency',
						currency: currency || FALLBACK_CURRENCY,
					}).format(value);
		}

		return currencySymbol ? `${currencySymbol}${formatNumber(value, amountFormat)}` : formatNumber(value, amountFormat);
	};
	return { format };
};

export const mapStripeErrorToTranslationKey = (error: StripeError): string => {
	switch (error.code as StripeErrorCodes) {
		case StripeErrorCodes.CARD_DECLINED:
			switch (error.decline_code as StripeDeclineCodes) {
				case StripeDeclineCodes.DO_NOT_HONOR:
				case StripeDeclineCodes.GENERIC_DECLINE:
					return 'stripe-error__card-declined-message';
				case StripeDeclineCodes.TRANSACTION_NOT_ALLOWED:
					return 'stripe-error__transaction-not-allowed-message';
				case StripeDeclineCodes.INVALID_ACCOUNT:
					return 'stripe-error__invalid-account-message';
				case StripeDeclineCodes.INSUFFICIENT_FUNDS:
					return 'stripe-error__insufficient-funds-message';
				default:
					return 'stripe-error__default-message';
			}
		case StripeErrorCodes.EXPIRED_CARD:
			return 'stripe-error__expired-card-message';
		case StripeErrorCodes.CARD_AUTHENTICATION_FAILED:
			return 'stripe-error__3DS-failure-message';
		case StripeErrorCodes.CARD_DECLINE_RATE_LIMIT_EXCEEDED:
			return 'stripe-error__card-decline-rate-limit-exceeded';
		default:
			return 'stripe-error__default-message';
	}
};

export const isDirectDebit = (paymentMethod: string) =>
	paymentMethod === 'bacs_debit' || paymentMethod === 'sepa_debit';

export const isOpenBanking = (paymentMethod: string) => paymentMethod === 'pay_by_bank';
