import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ErrorMessage = styled(Typography)(({ theme }) => ({
	margin: theme.spacing(2, 0, 4),
	color: theme.palette.grey[400],
	lineHeight: '20px',
	[theme.breakpoints.down('sm')]: {
		fontSize: 12,
	},
	'&:first-of-type:not(:only-of-type)': {
		margin: theme.spacing(2, 0, 0),
	},
	'&:last-of-type:not(:only-of-type)': {
		margin: theme.spacing(0, 0, 6),
	},
}));
