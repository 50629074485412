import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	minWidth: 300,
	padding: theme.spacing(4, 3),
	[theme.breakpoints.up('sm')]: {
		padding: theme.spacing(6),
	},
}));
