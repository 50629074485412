import { createContext } from 'react';

import { DEFAULT_CONFIG } from './constants';
import { ConfigurationWithPaymentData, InitialParameters } from './types';

export const ConfigurationContext = createContext<ConfigurationWithPaymentData>({
	config: DEFAULT_CONFIG,
	appVersion: 'unknown',
	data: { identifier: '', parameters: {} as InitialParameters },
	isQuickPayPage: false,
	isDraft: false,
	savedPayments: [],
	savePayment: () => {
		return {};
	},
	removePayment: () => {
		return {};
	},
	updateIdentifier: () => {
		return {};
	},
});
