import { styled } from '@mui/material/styles';

export const FooterLogo = styled('img')(({ theme }) => ({
	maxWidth: 250,
	maxHeight: 100,
	marginRight: theme.spacing(6),
	[theme.breakpoints.down('sm')]: {
		maxWidth: 120,
		maxHeight: 80,
		margin: theme.spacing(3, 0),
	},
}));
