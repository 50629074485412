import { Buffer } from 'buffer';

export class SessionStorage {
	static set(key: symbol | string, object: Record<string, unknown>) {
		const stringifiedObject = JSON.stringify(object);
		const encodedObject = Buffer.from(stringifiedObject).toString('base64');
		window.sessionStorage.setItem(key.toString(), encodedObject);
	}

	static get<T = unknown>(key: symbol | string): T | void {
		const rawValue = window.sessionStorage.getItem(key.toString());
		if (!rawValue) {
			return;
		}
		const decodedObject = Buffer.from(rawValue, 'base64').toString('utf-8');
		return JSON.parse(decodedObject);
	}

	static remove(key: symbol | string): void {
		window.sessionStorage.removeItem(key.toString());
	}
}
