import { styled } from '@mui/material/styles';

export const HeaderLogo = styled('img')(({ theme }) => ({
	maxWidth: 250,
	maxHeight: 150,
	[theme.breakpoints.down('sm')]: {
		maxHeight: 80,
		maxWidth: 120,
	},
}));
