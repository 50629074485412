import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { ErrorContainer } from './ErrorContainer';
import { ErrorImage } from './ErrorImage';
import { ErrorMessage } from './ErrorMessage';
import { ErrorTitle } from './ErrorTitle';
import { ReturnButton } from './ReturnButton';
import { CustomErrorPropTypes } from './types';
import errorImage from '../../../theme/assets/images/error.svg';

export const CustomError: FunctionComponent<CustomErrorPropTypes> = ({
	messages,
	title,
	onReturn,
	hideReturnButton = false,
}) => {
	const intl = useIntl();

	const buttonText = intl.formatMessage({ id: 'button__home' });

	return (
		<ErrorContainer component="main">
			<ErrorImage src={errorImage} alt="Man holding an error sign" />
			<ErrorTitle variant="h1" color="primary" align="center">
				{title}
			</ErrorTitle>
			{messages.map((message, index) => (
				<ErrorMessage variant="body2" color="primary" align="center" key={index}>
					{message}
				</ErrorMessage>
			))}

			{!hideReturnButton && (
				<ReturnButton variant="contained" color="secondary" onClick={onReturn} size="large" aria-label={buttonText}>
					{buttonText}
				</ReturnButton>
			)}
		</ErrorContainer>
	);
};
