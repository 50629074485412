import { common } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

const midScreenHeight = 706;

export const StyledModalContent = styled('div')(({ theme }) => ({
	background: common.white,
	maxHeight: ' calc(93vh - 35px)',
	justifyContent: 'space-between',
	maxWidth: '650px',
	minHeight: '80%',
	width: '95%',
	borderRadius: '20px',
	boxShadow: theme.palette.primary.main,
	display: 'flex',
	flexDirection: 'column',
	[theme.breakpoints.up('sm')]: {
		width: 550,
		minHeight: '300px',
	},
	[theme.breakpoints.up('lg')]: {
		width: 650,
		minHeight: '300px',
	},
	[`@media (max-height: ${midScreenHeight}px)`]: {
		minHeight: '95%',
	},
}));
