import { fetchWrapper, RequestMethod } from '../../../../helpers/fetchWrapper';
import { InitialQueryPayment } from '../types';

export const updatePaymentParameters =
	(paymentId: string, payments: InitialQueryPayment[], isQuickPay = false) =>
	async (): Promise<null> => {
		const body = payments;

		await fetchWrapper(`/${isQuickPay ? 'quick-payments' : 'payments'}/${paymentId}`, {
			body,
			method: RequestMethod.PATCH,
			returnRawResponse: true,
		});

		return null;
	};
