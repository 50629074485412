import { Box, BoxProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

interface FooterContainerProps extends BoxProps {
	hasBackground?: boolean;
}

export const FooterContainer = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'hasBackground',
})<FooterContainerProps>(({ theme, hasBackground }) => ({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: hasBackground ? theme.palette.secondary.main : 'transparent',
	padding: theme.spacing(4, 6),
	minHeight: 150,
	borderTop: '1px solid transparent',
	borderColor: !hasBackground ? alpha(theme.palette.secondary.main, 0.2) : undefined,
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(4, 1),
		flexDirection: 'column',
	},
}));
